import { Component, OnInit, ViewChild, Inject, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { TemplateService } from 'src/app/services/template.service';
import { StoreService } from 'src/app/services/store.service';
import { getToken, getProject, setFormUpdating, getImportCSVFileSetting, persitImportCSVFileSetting, getChoosenCRSType, removeImportCSVFileSetting, getCRSSetting, getTemplate } from 'src/app/share/utils';
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatTableDataSource, MatTable, MatDialog, MatStepper } from '@angular/material';
import { Project } from 'src/app/share/projects';
import { User } from 'src/app/share/user';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { Token } from 'src/app/share/token';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ImportErrorDlgComponent } from '../import-error-dlg/import-error-dlg.component';

const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'app-import-file-dialog',
  templateUrl: './import-file-dialog.component.html',
  styleUrls: ['./import-file-dialog.component.scss']
})
export class ImportFileDialogComponent implements OnInit {
  project: Project;
  template: Template;
  newTemplate: any;
  @Input() user: User;
  @Input() mode = 'Create';
  loading = false;
  loadingText = '';
  geomTypeList = ['Point', 'LineString', 'Polygon'];
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @ViewChild('imgIcon', {static: false}) img;
  @ViewChild('tabRef', {static: false}) private tabRef: MatStepper;


  selectedIndex: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  fileName: string;
  fileInBase64: string;
  fileToUpload: File;
  importInNewTemplate = false;
  importInExistingTemplate = false;
  isValidFile = true;
  importFileForm: FormGroup;
  importFileForm2: FormGroup;
  validFile = false;
  delimiters = [
    { value: ',', text: 'Comma' },
    { value: ';', text: 'Semi colon' }
  ];
  delimiter = ',';
  decimalSeparator = '.';
  utmZones = [];
  importDataError: any;
  featureSize = 0;

  crsList = [
    {value: 'Geodetic', text: 'Geodetic'},
    {value: 'Lambert72', text: 'Lambert 72'},
    {value: 'Lambert2008', text: 'Lambert 2008'},
    {value: 'RDNAP', text: 'RD'},
    {value: 'UTM', text: 'UTM'},
    {value: 'BGS2005', text: 'BGS2005'}
  ];

  fieldTypes = [
    {value: 'stringfield',  text: 'Text'},
    {value: 'integerfield', text: 'Integer'},
    {value: 'decimalfield', text: 'Decimal'},
    {value: 'booleanfield', text: 'Boolean'},
    {value: 'notefield',    text: 'Note'},
    {value: 'datefield', text: 'Date'},
    {value: 'timefield', text: 'Time'}
  ];

  coordsList = [
    'Latitude',
    'Longitude',
    'X', 'Y', 'Z',
    'N', 'E',
    'x','y','z',
    'n', 'e',
    'latitude',
    'longitude'
  ]


  fields = [];
  tempFields: any[] =  [];
  showFieldContainer = false;

  errMsg: string;
  submitingForm: boolean;
  formreseted: boolean;
  templates: any[];
  noFileIsSelected = false;

  parsedCsv: string[][];
  parsedCsvColumns: string[];
  csvContent: string;
  isCrsUTM: any = false;

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatTable, {static: false}) dataToImportTable: MatTable<any>;

  displayedColumns: string[];
  tempTemplateName: string;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = 'Test of message';
  showMessage = false;

  @ViewChild('fileInput', { static: false}) fileInput: ElementRef;
  @ViewChild('fform', { static: false}) importFileFormDirective;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    private templateService: TemplateService,
    private papaParser: Papa,
    protected elementRef: ElementRef,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.store.importDataInNewForm) {
      this.importInNewTemplate = this.store.importDataInNewForm;
    } else {
      this.importInExistingTemplate = !this.store.importDataInNewForm;
    }
    this.createForm();
    this.getListTemplates();
    this.selectedIndex.subscribe(res => {
    });
  }

  selectFile(): void {
    this.fileInput.nativeElement.click();
  }

  typeTemplateChange(event: MatRadioChange, data) {
    if (data === 'new_template') {
      this.importInNewTemplate = true;
      this.importInExistingTemplate = false;
      if(this.tempTemplateName && this.tempTemplateName.length > 0) {
        this.importFileForm.patchValue({
          template: this.tempTemplateName
        });
      }
    } else {
      if(this.importFileForm.value && this.importFileForm.value['template']){
        this.tempTemplateName = this.importFileForm.value['template'];
      }
      const t = getTemplate();
      if(t !== null) {
        this.importFileForm.patchValue({
          template: t.name
        });
      } else {
        this.importFileForm.patchValue({
          template: null
        });
      }
      this.importInNewTemplate = false;
      this.importInExistingTemplate = true;
    }
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    if (this.messageBox) {
      this.messageBox.showError();
    }
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      // this.messageBox.showSuccess();
    });
  }

  createForm() {
    let templateName = '';
    if (this.importInExistingTemplate) {
      if (this.store.template.value) {
        templateName = this.store.template.value.name;
      }
    }
    const csvConfig = getImportCSVFileSetting();
    let crs = 'Geodetic';
    if(csvConfig) {
      if (csvConfig['crs'] && csvConfig['crs'] !== "") {
        crs = csvConfig['crs'];
      } else {
        let savedCrs = getChoosenCRSType();
        if(savedCrs) {
          crs = savedCrs;
        } else {
          savedCrs = getCRSSetting();
          if(savedCrs) {
            crs = savedCrs;
          }
        }
      }
    } else {
      let savedCrs = getChoosenCRSType();
      if(savedCrs) {
        crs = savedCrs;
      } else {
        savedCrs = getCRSSetting();
        if(savedCrs) {
          crs = savedCrs;
        }
      }
    }

    if (this.mode === 'Create') {
      this.importFileForm = this.fb.group({
        csvFile: ['', Validators.required],
        crs: crs,
        template: [templateName, Validators.required],
        typetemplate: '',
      });
    }
  }

  goToNextStep() {
    this.tabRef.next();
  }

  closeDialog(event) {
    event.preventDefault();
    if (this.showFieldContainer) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          message: ``,
          title: 'Cancel import?'
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {
          this.fileToUpload = null;
          this.store.updatingForm = false;
          this.showFieldContainer = false;
          this.fields = null;
          this.fileInBase64 = null;
          this.store.hideTemplate();
        }
      });
    } else {
      this.fileToUpload = null;
      this.showFieldContainer = false;
      this.store.updatingForm = false;
      this.fields = null;
      this.fileInBase64 = null;
      this.store.hideTemplate();
    }
    event.stopPropagation();
  }

  setIndex(event): void {
    this.selectedIndex = new BehaviorSubject<number>(event);
    if (this.selectedIndex.getValue() > 0) {
      this.dataToImportTable.renderRows();
    }
    //console.log(this.selectedIndex.getValue());
  }

  constructCoordinateColumnsNbr(): any[] {
    if (this.fields && this.fields.length > 0) {
      const tempCoordColumns = [];
      let index = 0;
      let xIndex = null;
      let yIndex = null;
      let zIndex = null;
      for (const field of this.fields) {
        ++index;
        if (field.isCoordinate) {
          if (field.typeCoordinate.toLowerCase() === 'lat' || field.typeCoordinate.toLowerCase() === 'latitude'
          || field.typeCoordinate.toLowerCase() === 'x' || field.typeCoordinate.toLowerCase() === 'n' ) {
            xIndex = index;
          } else if (field.typeCoordinate.toLowerCase() === 'lon' || field.typeCoordinate.toLowerCase() === 'longitude'
          || field.typeCoordinate.toLowerCase() === 'y' || field.typeCoordinate.toLowerCase() === 'e') {
            yIndex = index;
          } else if (field.typeCoordinate.toLowerCase() === 'z') {
            zIndex = index;
          }
        }
      }
      if (xIndex) {
        tempCoordColumns.push(xIndex);
      }
      if (yIndex) {
        tempCoordColumns.push(yIndex);
      }
      if (zIndex) {
        tempCoordColumns.push(zIndex);
      }
      return tempCoordColumns;
    }
    return null;
  }

  constructDataFields(): any[] {
    if (this.fields && this.fields.length > 0) {
      const locTempFields = [];
      for (const field of this.fields) {
        if (!field.isCoordinate) {
          const tempField = {
            _class: field._class,
            name: field.name,
            label: field.label,
            description: field.description,
            enabled: true,
            visible: true,
            required: false,
            use_previous_value: false,
            constraints: [],
            event_actions: null,
            default_value_expression: null
          };
          locTempFields.push(tempField);
        }
      }
      return locTempFields;
    }
    return [];
  }

  getDefaultType(type) {
    return 'Text';
  }

  checkCoordinateType(event, field): void {
    for (const f of this.fields) {
      if (f.isCoordinate) {
        if (f.name !== field.name) {
          if (f.typeCoordinate === event) {
            this.showErrorMessage('The choosed coordinate type is already choosen');
            field.typeCoordinate = null;
            event = null;
            return;
          }
        }
      }
    }
  }

  closePanel() {
    this.showMessage = false;
  }


  showErrorDlg(): void {
    const dialogRef = this.dialog.open(ImportErrorDlgComponent, {
      width: '500px',
      data: {
        error: this.importDataError
      }
    });
    dialogRef.afterClosed().subscribe(result  => {

    });
  }

  onSubmit() {
    if (!this.fileToUpload && !this.fileInBase64) {
      this.noFileIsSelected = true;
      this.showErrorMessage('No file is selected');
      return;
    }


    this.showMessage = false;
    const importData = this.importFileForm.value;
    for(const temp of this.store.templates) {
      if(temp['name'] === importData.template && this.importInNewTemplate) {
        this.showErrorMessage('A form with the same name already exists within the selected project.');
        return;
      }
    }

    const fileNamesParts = importData.csvFile.split('.');
    let fileType = '';
    if (fileNamesParts && fileNamesParts.length > 0) {
      fileType = fileNamesParts[fileNamesParts.length - 1];
    }
    let p = getProject();
    const value = {
      template: {
        name: importData.template,
        description: importData.template,
        project_id: p.id,
        image_file_url: null
      },
      data: {
        base64_encoded_content: this.fileInBase64,
        data_separator : this.delimiter,
        encoding: 'UTF8',
        type: 'CSV'
        // fields: this.importInNewTemplate ? this.constructDataFields() : []
      }
    };
    const coordColumns = this.constructCoordinateColumnsNbr();

    // if (this.delimiter === ';') {
    value['data']['decimal_separator'] = this.decimalSeparator;
    // }

    if (coordColumns && coordColumns.length > 0 && importData.crs) {
      value['data']['geometry'] =  'Point';
      value['data']['crs'] =  importData.crs;
      value['data']['coordinate_columns'] =  coordColumns;
    } else {
      value['data']['geometry'] =  null;
    }

    const constructedfields = this.constructDataFields();
    if (this.importInNewTemplate) {
      value['data']['fields'] =  constructedfields;
    }
    const csvConfig = {};
    csvConfig['filename'] = importData.csvFile;
    const fs = [];
    if (constructedfields && constructedfields.length > 0) {
      for(const constfield of constructedfields) {
        const f = {
          name: constfield['name'],
          _class: constfield['_class']
        }
        fs.push(f);
      }
      csvConfig['fields'] = fs;
    }
    persitImportCSVFileSetting(csvConfig);
    //console.log(JSON.stringify(value));
    this.store.showLoading();
    this.loadingText = 'Sending importation data';
    const token = getToken();
    this.templateService.importDataInTemplate(value, token, p.id).subscribe(
      res => {
        console.log(res);
        this.showSuccessMessage(`Importation succeed for form: ${res.name}` );
        setFormUpdating(false);
        this.store.updatingForm = false;
        if (this.importInNewTemplate) {
          this.store.createTemplate(res);
          this.store.readTemplate(res);
        } else {
          this.store.createTemplate(res);

          this.store.readTemplate(res);
          this.store.hideLoading();
          //this.store.showTemplateFeatureData();
        }
        /*
        setTimeout(() => {
          this.store.hideTemplate();
          this.getTemplates(p);
        }, 4000);
        */
        //console.log(res);
        this.store.hideLoading();
      },
      error => {
        this.loading = false;
        this.store.hideLoading();
        setFormUpdating(false);
        this.store.updatingForm = false;
        // p = getProject();
        // this.getTemplates(p);
        if (error instanceof Array) {
          this.importDataError = error;
        } else {
          this.importDataError = [error]
        }
        let msg = error ? `Error: ${error}` : `Importation failed, please retry later`;
        if (error instanceof Array) {
          // const isOrAre = error.length > 1 ? 'are' : 'is';
          const record = error.length > 1 ? 'records' : 'record';
          msg = `${error.length} ${record} could not be imported.` ;
        }
        this.showErrorMessage(msg);
        /*
        setTimeout(() => {
          this.store.hideTemplate();
        }, 5000);
        */
      }
    );
  }

  handleData(data: any) {
    setTimeout(() => {
      // data.isCoordinate = !data.isCoordinate;
    });
  }

  getTemplates(p): void {
    const token: Token = JSON.parse(localStorage.getItem('token'));

    this.store.showLoading();
    this.templateService.getTemplates(token, p.id).subscribe(
      res => {
        this.store.readTemplates(res);
        this.store.hideLoading();
      },
      errmess => {
        this.store.hideLoading();
        this.showErrorMessage(errmess);
    });
  }

  onlyDigits(str) {
    const pattern = /^\d+$/;
    return pattern.test(str);  // returns a boolean
  }

  setFileData(event): void {
    event.preventDefault();
    this.showFieldContainer = false;

    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];
      this.isValidFile = true;
      const filename = tempFile.name;
      const filenameParts = filename ? filename.split('.') : [];
      if (filenameParts && filenameParts.length > 1) {
        this.importFileForm.patchValue({
          template: filenameParts[0]
        });
      }

      // setFormUpdating(true);
      this.store.updatingForm = true;
      this.fileToUpload = event.target.files[0];
      this.papaParser.parse(this.fileToUpload, {
        dynamicTyping: true,
        worker: true,
        skipEmptyLines: true,
        chunk: (chunk) => {
          try {
            // const headers = chunk.data.splice(0, 1)[0];
            this.displayedColumns  = chunk.data.splice(0, 1)[0];
            // this.showFieldContainer = false;
            this.delimiter = chunk.meta.delimiter;

            if (this.delimiter === ';') {
              this.decimalSeparator = ',';
            }
            this.fields  = [];
            let checkConfig = false;
            const csvConfig = getImportCSVFileSetting();
            let saveFields = [];

            if (csvConfig) {
              const filename = csvConfig['filename'];
              if (filename && filename === this.fileToUpload.name) {
                checkConfig = true;
                saveFields = csvConfig['fields'];
              } else {
                removeImportCSVFileSetting();
              }
            }

            if (this.displayedColumns && this.displayedColumns.length > 0) {
              for (const i of this.displayedColumns) {
                if (i) {
                  if (this.onlyDigits(i)) {
                    this.displayedColumns = [];
                    this.dataSource.data = [];
                    this.fields = [];
                    this.showFieldContainer = false;
                    this.isValidFile = false;
                    break;
                  } else {
                    const val2 = i.toString().trim().toLowerCase();
                    if (val2 === 'x' || val2 === 'y' || val2 === 'z' ||
                       val2 === 'lat' || val2 === 'lon' || val2 === 'latitude' ||
                       val2 === 'longitude' || val2 === 'n' || val2 === 'e') {
                      this.fields.push( {
                        name: val2,
                        label: val2,
                        description: val2,
                        _class: 'decimalfield',
                        isCoordinate: true,
                        typeCoordinate: val2
                      });
                    } else {
                      let _class = 'stringfield';
                      if(checkConfig && saveFields.length > 0) {
                        for(const f of saveFields) {
                            if (f['name'] === i) {
                              _class = f['_class'];
                              break;
                            }
                        }
                      }
                      this.fields.push({
                        name: i,
                        label: i,
                        description: i,
                        _class: _class,
                        isCoordinate: false,
                        typeCoordinate: ''
                      });
                    }
                  }
                } else {
                  // this.showErrorMessage('Please verify your file, it contains invalid header');
                  this.displayedColumns = [];
                  this.dataSource.data = [];
                  this.fields = [];
                  this.showFieldContainer = false;
                  this.isValidFile = false;
                  break;
                }
              }
            }
            if (this.isValidFile) {
              this.showFieldContainer = true;
              const results = chunk.data;
              const data = [];
              this.featureSize = results.length;
              for (const d of results) {
                const item = {};
                if (data.length === 25) {
                  break;
                }
                for (let i = 0; i < this.displayedColumns.length; i++) {
                  const key = this.displayedColumns[i];
                  const value = d[i];
                  item[key] = value;
                }
                data.push(item);
              }
              if(data.length > 0) {
                const firstItem = data[0]
                const keys = Object.keys(firstItem);
                let pointSeparatorFound = false;
                for(let key of keys) {
                  if (key.toLowerCase() === 'x' || key.toLowerCase() === 'y' || key.toLowerCase() === 'z' ||
                    key.toLowerCase() === 'lat' || key.toLowerCase() === 'lon' || key.toLowerCase() === 'latitude' ||
                    key.toLowerCase() === 'longitude' || key.toLowerCase() === 'n' || key.toLowerCase() === 'e') {
                    const val = firstItem[key].toString();
                    if(val.indexOf(".") >= 0) {
                      pointSeparatorFound = true;
                    }
                  }
                }
                if (pointSeparatorFound) {
                  this.decimalSeparator = "."
                }
              }

              this.dataSource.data = data;
            } else {
              this.message = 'Error in CSV file';
              this.importDataError = ['The header of the file is missing or is not correct.'];
              this.isValidFile = true;
              this.showMessage = true;
              return;
            }
          } catch (error) {
            this.message = 'Error in CSV file';
            this.importDataError = ['The header of the file is missing or is not correct.'];
            this.showMessage = true;
          }
        },
        complete: (results, file) => {
          if (results) {

          }
          this.convertFileToBase64(this.fileToUpload);
          this.importFileForm.get('csvFile').setValue(this.fileToUpload.name);

        },
        error: (err) => {
          console.log(err);
        }
      });
      event.stopPropagation();
    }
  }

  convertFileToBase64(file) {
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryString = reader.result;
            this.fileInBase64 = btoa(binaryString as string);
        };
        reader.readAsBinaryString(file);
    }
  }

  onFileSelect() {
    const content = this.csvContent;
    if (this.fileToUpload) {
      const fileReader = new FileReader();
      fileReader.onload = this.onFileLoad;
      fileReader.readAsText(this.fileToUpload, 'UTF-8');
    }
  }

  changedFieldType(fieldType, f): void {
    if (fieldType && fieldType === 'decimalfield') {
      const name = f.name;
      const val = this.dataSource.data[0][name];
      if (val && val.indexOf(',') >= 0) {
        this.decimalSeparator = ',';
      } else {
        this.decimalSeparator = '.';
      }
    } else if (fieldType && fieldType === 'notefield') {
      f['html'] = '';
    }
  }

  onFileLoad(): void {
    console.log(this.parsedCsv);
    this.displayedColumns = this.parsedCsv[0];
    this.fields = [];
    this.showFieldContainer = false;
    if (this.displayedColumns && this.displayedColumns.length > 0) {
      for (const i of this.displayedColumns) {
        if (i === 'X' || i === 'Y' || i === 'Lat' || i === 'Lon' ||
         i === 'Latitude' || i === 'Longitude' || i === 'N' || i === 'E') {
          const tempField = {
            name: i,
            label: i,
            description: i,
            _class: 'decimalfield',
            isCoordinate: true
          };
        } else {
          this.fields.push({name: i, label: i, description: i, _class: 'stringfield', isCoordinate: false });
        }
      }
    }
    this.showFieldContainer = true;
    this.tempFields = [...this.fields];

    const data = [];
    let index = 0;
    for (const d of this.parsedCsv) {
      if (index++ === 0) {
        continue;
      }
      const item = {};
      for (let i = 0; i < this.displayedColumns.length; i++) {
        const key = this.displayedColumns[i];
        const value = d[i];
        item[key] = value;
      }
      data.push(item);
    }
    console.log(JSON.stringify(this.fields));

    // this.dataSource.data = data;
    // this.dataToImportTable.renderRows();
  }

  convertFileToString(): Observable<any> {
    return null;
  }

  getListTemplates(): void {
    const token = getToken();
    const prjt = getProject();
    // this.store.showLoading();
    this.loadingText = 'Loading existed templates ....';
    this.loading = true;
    this.templateService.getTemplates(token, prjt.id).subscribe(
      res => {
        this.templates = res;
        this.loading = false;
        // this.store.hideLoading();
      },
      err => {
        this.loading = false;
        // this.store.hideLoading();
      }
    );
  }
}
