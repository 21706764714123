import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CrsService } from 'src/app/services/crs.service';
import { StoreService } from 'src/app/services/store.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTable, MatTableDataSource } from '@angular/material';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { getCRSSetting, getProject, getTemplate, getToken, getUTMZone } from 'src/app/share/utils';
import { FeatureService } from 'src/app/services/feature.service';

const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'app-edit-geom-or-coord-values',
  templateUrl: './edit-geom-or-coord-values.component.html',
  styleUrls: ['./edit-geom-or-coord-values.component.scss']
})


export class EditGeomOrCoordValuesComponent implements OnInit, AfterViewInit  {
  coordinates: any[];
  geodetic: boolean;
  isCoordAttributes: boolean = false;
  geomType: string;
  geometryData: any[];
  coordAttributesData: any[];
  displayedColumns: string[] = [];
  feature: any;
  coordModified: boolean = false;
  loading: boolean = false;
  error_occured: boolean = false;
  crs: any;
  latitude: string;
  longitude: string;
  altitude: string;
  accuracy: string;
  saved_accuraty: any;
  column_name: string;
  is_fieldset_array: boolean;
  fsa_value_index: number;
  err_message = "";

  @ViewChild(MatTable, {static: true}) coordinatestable: MatTable<any>;

  constructor(
    private conversionCoordinateService: CrsService,
    private store: StoreService,
    private featureService: FeatureService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditGeomOrCoordValuesComponent>) {
      this.geomType = null;
      this.isCoordAttributes = null;
      if (data !== undefined && data !== null) {
        this.feature = data.feature;
        if (data.isCoordAttributes) {
          // In case of Coordinate field
          this.column_name = data.column;
          this.isCoordAttributes = data.isCoordAttributes;
          this.coordAttributesData = data.coordAttributes;
          this.is_fieldset_array = data.is_fieldset_array;
          this.fsa_value_index = data.fsa_value_index;
        } else {
          if (this.feature.geometry){
            this.geometryData = data.feature.geometry.coordinates;
            this.geomType = data.feature.geometry.type;
          }
        }
      }
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.error_occured = false;
    this.crs = getCRSSetting();
    if(this.crs === null || this.crs === undefined || this.crs === '' || this.crs === 'Geodetic') {
      this.geodetic = true;
      this.latitude = 'Latitude (°)';
      this.longitude = 'Longitude (°)';
      this.altitude = 'Elevation (m)';
      this.accuracy = 'Accuracy ';
    } else {
      this.geodetic = false;
      this.latitude = 'X';
      this.longitude = 'Y';
      this.altitude = 'Z';
      this.accuracy = 'Accuracy';
    }
    if (this.isCoordAttributes) {
      this.convertCoordinates(this.coordAttributesData);
    } else {
      this.convertCoordinates(this.geometryData);
    }
  }

  createTableHeaderArrayWithinTemplate(): any[] {
    const template = getTemplate();
    let result = [];
    const form = template.form;
    const fields = form.fields;
    for (const field of fields) {
      const attlabel = field['label'];
      const attname = field['name'];
      if (field['_class'] === 'fieldset' || field._class === 'arrayfieldset') {
        result = result.concat(this.createTableHeaderWithinAttributeSet(field, attname, attlabel));
      } else {
        const obj = {
          name: field['name'],
          label: field['label']
        }
        result.push(obj);
      }
    }
    return result;
  }

  createTableHeaderWithinAttributeSet(fieldset, nameparent, labelparent): string[] {
    let obj = [];
    let attsetName = fieldset['label'];
    if (attsetName === null || attsetName === undefined || attsetName === '') {
      attsetName = '';
    }

    for (const field of fieldset.fields) {
      if (field._class === 'fieldset' || field._class === 'arrayfieldset') {
        const label = `${labelparent} ${field.label}`;
        const name = `${nameparent}.${field.name}`
        obj = obj.concat(this.createTableHeaderWithinAttributeSet(field, name, label));
      } else {
        const attlabel = `${labelparent} ${field.label}`;
        const attname = `${nameparent}.${field.name}`;
        const temp_obj = {
          name: attname,
          label: attlabel
        }
        obj.push(temp_obj);
      }
    }
    return obj;
  }

  updateNestedObjectByPath(obj, path, newValue, index = null) {
    // Split the path into an array of keys
    const keys = path.split('.');

    // Traverse through the keys to reach the target object
    let currentObj = obj;
    for (let i = 0; i < keys.length - 1; i++) {
        currentObj = currentObj[keys[i]];
        if (!currentObj) {
            return;
        }
    }

    // Update the value of the target key
    const targetKey = keys[keys.length - 1];
    if(index !== null && index !== undefined && Number(index) >= 0){
      currentObj[index][targetKey] = newValue;
    } else if (currentObj.hasOwnProperty(targetKey)) {
       currentObj[targetKey] = newValue;
    }
  }

  updateCoordinates() {
    this.error_occured = false;
    const updatedFeature = this.feature;
    const project = getProject();
    const token = getToken();

    const template = getTemplate();

    if (this.crs === null || this.crs === undefined || this.crs === '' || this.crs === 'Geodetic') {
      this.crs = 'Geodetic';
      const new_coordinates = this.constructGeodeticNewCoordinates(this.coordinates, this.geomType);
      if(this.isCoordAttributes) {
          const attr_list = this.createTableHeaderArrayWithinTemplate();
          let attribute_column = '';
          for(const obj of attr_list) {
            if (obj['label'] === this.column_name) {
              attribute_column = obj['name'];
              break;
            }
          }
          let attributes = this.feature.attributes;
          if(this.is_fieldset_array) {
            this.updateNestedObjectByPath(attributes, attribute_column,new_coordinates, this.fsa_value_index);
          } else {
            this.updateNestedObjectByPath(attributes, attribute_column,new_coordinates);
          }

          updatedFeature.attributes = attributes;
      } else {
          //updatedFeature['geometry']['coordinates'] = new_coordinates;
          updatedFeature['geometry']['coordinates'] = new_coordinates;
      }

      this.loading = true;
      this.featureService.updateFeature(updatedFeature, template, project, token)
      .subscribe(
        res => {
          const tempFeat = (feat) => feat.id === res.id;
          this.loading = false;
          this.dialogRef.close(res);
        },
        err => {
          this.showErrorMessage(err);
          this.loading = false;
      });

    } else {
      this.loading = true;
      if(!this.geomType) {
        this.coordinates = this.coordinates.map(item => ({
          X: parseFloat(item.X),
          Y: parseFloat(item.Y),
          Z: parseFloat(item.Z)
        }));        
      }
      const zone = getUTMZone();
      this.conversionCoordinateService.convertCRSToGeodetic(token, this.crs, this.coordinates, zone).subscribe(
        res => {
          this.geodetic = false;
          this.coordinates = res;
          const new_coordinates = this.constructNonGeodeticNewCoordinates(this.coordinates, this.geomType);

          if(this.isCoordAttributes) {
            const attr_list = this.createTableHeaderArrayWithinTemplate();
            let attribute_column = '';
            for(const obj of attr_list) {
              if (obj['label'] === this.column_name) {
                attribute_column = obj['name'];
                break;
              }
            }
            let attributes = this.feature.attributes;
            if(this.is_fieldset_array) {
              this.updateNestedObjectByPath(attributes, attribute_column,new_coordinates, this.fsa_value_index);
            } else {
              this.updateNestedObjectByPath(attributes, attribute_column,new_coordinates);
            }
            updatedFeature.attributes = attributes;
          } else {
            updatedFeature['geometry']['coordinates'] = new_coordinates;
          }
          this.loading = true;
          this.featureService.updateFeature(updatedFeature, template, project, token)
          .subscribe(
            res => {
              this.loading = false;
              this.dialogRef.close(res);
            },
            err => {
              this.showErrorMessage(err);
              this.loading = false;
          });

          this.loading = false;
        },
        err => {
          console.log(err);
          this.err_message = err;
          this.error_occured = true;
          this.loading = false;
        }
      );
    }
  }

  constructGeodeticNewCoordinates(coordinates, type='Point'){
    const coord = [];
    if (!this.geomType) {
      for(let i = 0; i < coordinates.length; i++){
        const item = coordinates[i];
        const accuracy = item['accuracy'] ? parseFloat(item['accuracy']) : 0;
        coord.push(parseFloat(item['latitude']));
        coord.push(parseFloat(item['longitude']));
        coord.push(parseFloat(item['altitude']));
        coord.push(accuracy);
      }
      return coord;
    }
    if (type !== 'Point') {
      if (type === 'LineString' || type === 'Linestring'){
          for(let i = 0; i < coordinates.length; i++){
            const item = coordinates[i];
            const temp_coord = [];
            const accuracy = isNaN(item['accuracy']) ?  0 : parseFloat(item['accuracy']);
            temp_coord.push(parseFloat(item['latitude']));
            temp_coord.push(parseFloat(item['longitude']));
            temp_coord.push(parseFloat(item['altitude']));
            temp_coord.push(accuracy);
            coord.push(temp_coord);
          }
      } if (type === 'Polygon'){
        const polygon_coord = []
        for(let i = 0; i < coordinates.length; i++){
          const item = coordinates[i];
          const temp_coord = [];
          const accuracy = isNaN(item['accuracy']) ?  0 : parseFloat(item['accuracy']);
          temp_coord.push(parseFloat(item['longitude']));
          temp_coord.push(parseFloat(item['latitude']));
          temp_coord.push(parseFloat(item['altitude']));
          temp_coord.push(accuracy);
          polygon_coord.push(temp_coord);
        }
        coord.push(polygon_coord);
      }
    } else  {
      for(let i = 0; i < coordinates.length; i++){
        const item = coordinates[i];
        const accuracy = item['accuracy'] ? parseFloat(item['accuracy']) : 0;
        coord.push(parseFloat(item['latitude']));
        coord.push(parseFloat(item['longitude']));
        coord.push(parseFloat(item['altitude']));
        coord.push(accuracy);
      }
    }
    return coord;
  }

  constructNonGeodeticNewCoordinates(coordinates, type='Point'){
    const coord = [];
    if (this.geomType) {
      if (type !== 'Point') {
        if (type === 'LineString' || type === 'Linestring'){
            for(let i = 0; i < coordinates.length; i++){
              const item = coordinates[i];
              const temp_coord = [];
              temp_coord.push(parseFloat(item['latitude']));
              temp_coord.push(parseFloat(item['longitude']));
              temp_coord.push(parseFloat(item['altitude']));
              coord.push(temp_coord);
            }
        } if (type === 'Polygon'){
          const polygon_coord = []
          for(let i = 0; i < coordinates.length; i++){
            const item = coordinates[i];
            const temp_coord = [];
            temp_coord.push(parseFloat(item['longitude']));
            temp_coord.push(parseFloat(item['latitude']));
            temp_coord.push(parseFloat(item['altitude']));
            polygon_coord.push(temp_coord);
          }
          coord.push(polygon_coord);
        }
      } else {
        for(let i = 0; i < coordinates.length; i++){
          const item = coordinates[i];
          coord.push(parseFloat(item['latitude']));
          coord.push(parseFloat(item['longitude']));
          coord.push(parseFloat(item['altitude']));
          coord.push(parseFloat(this.saved_accuraty));
        }
      }
    } else {
      for(let i = 0; i < coordinates.length; i++){
        const item = coordinates[i];
        coord.push(parseFloat(item['latitude']));
        coord.push(parseFloat(item['longitude']));
        coord.push(parseFloat(item['altitude']));
        coord.push(parseFloat(this.saved_accuraty));
      }
    }

    return coord;
  }

  showErrorMessage(err) {
    this.err_message = err;
    this.error_occured = true;
  }

  convertCoordinates(geodCoordinates) {
    this.coordinates = [];
    let crs = getCRSSetting();
    if (this.geomType) {
      if (this.geomType === 'Point') {
        this.saved_accuraty  = 0.0;
        if (geodCoordinates.length >= 4) {
          if(typeof(geodCoordinates[3]) === 'string') {
            this.saved_accuraty = Number(geodCoordinates[3]);
          } else {
            this.saved_accuraty = geodCoordinates[3].toFixed(3);
          }
        }

        if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0 && geodCoordinates[2]  === 0)) {
            const el = {
              latitude : geodCoordinates[0].toFixed(8),
              longitude : geodCoordinates[1].toFixed(8),
              altitude : geodCoordinates[2].toFixed(8),
              accuracy: this.saved_accuraty !== 0.0 ? this.saved_accuraty : '?'
            };
            this.coordinates.push(el);
        }
      } else if (this.geomType === 'LineString' || this.geomType === 'Linestring') {
        geodCoordinates.forEach(geodCoordinate => {
          let accuracy = 0.0;
          if (geodCoordinate.length >= 4) {
            if(typeof(geodCoordinate[3]) ==='string') {
              accuracy = Number(geodCoordinate[3]);
            } else {
              accuracy = geodCoordinate[3].toFixed(3);
            }
          }
          if(!(geodCoordinate[0] === 0 && geodCoordinate[1] === 0  && geodCoordinate[2]  === 0)) {
              const item = {
                latitude : geodCoordinate[0].toFixed(8),
                longitude : geodCoordinate[1].toFixed(8),
                altitude : geodCoordinate[2].toFixed(8),
                accuracy: accuracy !== 0.0 ? accuracy : '?'
              };
              this.coordinates.push(item);
          }
        });
      } else if (this.geomType === 'Polygon') {
        geodCoordinates.forEach(element => {
          element.forEach(geodCoordinate => {
            let accuracy = 0.0;
            if (geodCoordinate.length >= 4) {
              //accuracy = geodCoordinate[3].toFixed(2);
              if(typeof(geodCoordinate[3]) ==='string') {
                accuracy = Number(geodCoordinate[3]);
              } else {
                accuracy = geodCoordinate[3].toFixed(3);
              }
            }

            if(!(geodCoordinate[0] === 0 && geodCoordinate[1] === 0  && geodCoordinate[2]  === 0)) {
                const item = {
                  latitude : geodCoordinate[0].toFixed(8),
                  longitude : geodCoordinate[1].toFixed(8),
                  altitude : geodCoordinate[2].toFixed(8),
                  accuracy: accuracy !== 0.0 ? accuracy : '?'
                };
                this.coordinates.push(item);
            }
          });
        });
      }
    } else {
      this.saved_accuraty = 0.0;
      if (geodCoordinates.length >= 4) {
        if(typeof(geodCoordinates[3]) ==='string') {
          this.saved_accuraty = Number(geodCoordinates[3]).toFixed(3);
        } else {
          this.saved_accuraty = geodCoordinates[3].toFixed(3);
        }

      }
      if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0  && geodCoordinates[2]  === 0)) {
          const el = {
            latitude : geodCoordinates[0].toFixed(8),
            longitude : geodCoordinates[1].toFixed(8),
            altitude : geodCoordinates[2].toFixed(8),
            accuracy: this.saved_accuraty
          };
          this.coordinates.push(el);
      }
    }

    if (crs === null || crs === undefined || crs === '' || crs === 'Geodetic') {
      crs = 'Geodetic';
      this.geodetic = true;
    } else {
      const token = getToken();
      this.loading = true;
      debugger;
      this.conversionCoordinateService.convertCRS(token, crs, this.coordinates).subscribe(
        res => {
          // if(this.geomType) {
            const data = [];
            for(const v of res) {
              const coord = {
                X: v['X'].toFixed(3),
                Y: v['Y'].toFixed(3),
                Z: v['Z'].toFixed(3)
              };
              data.push(coord);
            }

            this.coordinates = data;
          // } else {
          //   this.coordinates = res;
          // }
          this.loading = false;
        },
        err => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }

  updateElementValue(event, index, element, value) {
    if(!value)
      value = event.target.value;
    this.coordModified = true;
    this.coordinates[index][element] = parseFloat(value);
  }

  createHeaderArray(geodetic) {
    this.displayedColumns = [];
    if (geodetic) {
      this.displayedColumns.push('Latitude (°)');
      this.displayedColumns.push('Longitude (°)');
      this.displayedColumns.push('Elevation (m)');
      this.displayedColumns.push('Accuracy');
    } else {
      this.displayedColumns.push('X');
      this.displayedColumns.push('Y');
      this.displayedColumns.push('Z');
      this.displayedColumns.push('Accuracy');
    }
  }

  createDataArray() {
    let data = [];
    if (this.geodetic) {
      this.coordinates.forEach(coordinate => {
        let accuracy = 0.0;
        if (coordinate['accuracy'] !== null && coordinate['accuracy'] !== undefined && coordinate['accuracy'] !== '?') {
          accuracy = coordinate['accuracy'].toFixed(2);
        }
        const coord = {
          Latitude: coordinate['latitude'].toFixed(8),
          Longitude: coordinate['longitude'].toFixed(8),
          Elevation: coordinate['altitude'].toFixed(2),
          Accuracy: accuracy !== 0.0 ? accuracy : '?'
        };
        data.push(coord);
      });
    } else {
      this.coordinates.forEach(coordinate => {
        let accuracy = 0.0;
        if (coordinate['accuracy'] !== null && coordinate['accuracy'] !== undefined && coordinate['accuracy'] !== '?') {
          accuracy = coordinate['accuracy'].toFixed(2);
        }
        const coord = {
          X: coordinate['X'].toFixed(2),
          Y: coordinate['Y'].toFixed(2),
          Z: coordinate['Z'].toFixed(2),
          Accuracy: accuracy !== 0.0 ? accuracy : '?'
        };
        data.push(coord);
      });
    }
  }

  closeDialog() {
    if (this.coordModified) {
      const confirmRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          message: `Changes are not saved, do you want to close the Dialog?`,
          title: 'Confirmation ?'
        }
      });
      confirmRef.afterClosed().subscribe(result  => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }

  }

}
