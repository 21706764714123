import { Token } from './token';
import { environment } from 'src/environments/environment';
export const URL = environment.URL;

export const Domain = environment.BASE_API_URL;

export const API_URL = environment.BASE_API_URL;

export const DOMAIN = environment.DOMAIN;

export const HOME_URI = '/';

export const SEPARATOR = '_';

export const TEMPLATE_TO_SHOW = 'template_to_show';

export const X_AUTH_TOKEN         = 'X-Auth-Token';
export const X_ADMIN_AUTH_TOKEN   = 'X-Admin-Auth-Token';
export const X_WORKING_PROJECT_ID = 'X-Working-Project-Id';

export const ERROR    = 'error';
export const WARNING  = 'warning';
export const CRITICAL = 'critical';
export const SUCCESS  = 'success';

export const UPDATING_FORM = 'UPDATING_FORM';

export const ApiError = {
  DuplicateResource:     82,
  InvalidCredentials:    42,
  MissingParameter:      58,
  UserNotActivated:      54,
  InvalidUsername:       60,
  DatabaseAccessTimeout: 63,
  Unexpected:            128
};

export const TokenType = {
  PointToken:       '.',
  StartArrayToken:  '[',
  EndArrayToken:    ']',
  StartObjetToken:  '{',
  EndObjectToken:   '}'
};

export const Http = {
  Ok:                  200,
  Created:             201,
  Accepted:            202,
  BadRequest:          400,
  Unauthorized:        401,
  Forbidden:           403,
  NotFound:            404,
  InternalServerError: 500
};

export const Permissions = {
  Create:         'create',
  Read:           'read',
  Update:         'update',
  Delete:         'delete',
  CreateTemplate: 'template.create',
  ReadTemplate:   'templates.read',
  UpdateTemplate: 'template.update',
  DeleteTemplate: 'template.delete',
  CreateFeature:  'feature.create',
  ReadFeature:    'features.read',
  UpdateFeature:  'feature.update',
  DeleteFeature:  'template.create',
  CreateForm:     'form.create',
  ReadForm:       'forms.read',
  UpdateForm:     'form.update',
  DeleteForm:     'form.delete',
};

export function url(endpoint) {
  return getCurrentApiURL() + endpoint;
}

export function persist(key, item) {
  localStorage.setItem(key, item);
}

export function get(key) {
  return localStorage.getItem(key);
}

export function remove(key) {
  localStorage.removeItem(key);
}

export function setFormUpdating(isUpdating: boolean) {
  localStorage.setItem(UPDATING_FORM, JSON.stringify(isUpdating));
}

export function getFormUpdating(): boolean {
  const formUpdating = localStorage.getItem(UPDATING_FORM);
  if (formUpdating === undefined || formUpdating === 'undefined' || formUpdating === null) {
    return false;
  }
  const val = JSON.parse(formUpdating);
  return val;
}

export function persistToken(token) {
  localStorage.setItem('token', JSON.stringify(token));
}

export function getToken() {
  const token = localStorage.getItem('token');
  if (token !== undefined && token !== 'undefined' && token !== null) {
    return JSON.parse(token);
  }
  return null;
}

export function persistEnexisSetting(enexis) {
  localStorage.setItem('enexis', JSON.stringify(enexis));
}

export function getEnexisSetting() {
  const enexis = localStorage.getItem('enexis');
  if (enexis !== undefined && enexis !== 'undefined' && enexis !== null) {
    return JSON.parse(enexis);
  }
  return null;
}

export function removeEnexisSetting() {
  localStorage.removeItem('enexis');
}

export function persistSearchValue(value) {
  localStorage.setItem('search_value', JSON.stringify(value));
}

export function getSearchValue() {
  const searchValue = localStorage.getItem('search_value');
  if (searchValue) {
    return JSON.parse(searchValue);
  }
  return null;
}

export function equalsIgnoringCase(text, other) {
  return text.localeCompare(other, undefined, { sensitivity: 'base' });
}

export function removeSearchValue() {
  localStorage.removeItem('search_value');
}

export function persitImportCSVFileSetting(fileConfig) {
  localStorage.setItem('cvs_config', JSON.stringify(fileConfig));
}

export function getImportCSVFileSetting() {
  const csvFileConfig = localStorage.getItem('cvs_config');
  if (csvFileConfig) {
    return JSON.parse(csvFileConfig);
  }
  return null;
}

export function removeImportCSVFileSetting() {
  localStorage.removeItem('cvs_config');
}

export function persitImportExcelFileSetting(fileConfig) {
  localStorage.setItem('excel_config', JSON.stringify(fileConfig));
}

export function getImportExcelFileSetting() {
  const csvFileConfig = localStorage.getItem('excel_config');
  if (csvFileConfig) {
    return JSON.parse(csvFileConfig);
  }
  return null;
}

export function removeImportExcelFileSetting() {
  localStorage.removeItem('excel_config');
}

export function persistCurrentFeaturesUri(uri) {
  localStorage.setItem('current_features_uri', JSON.stringify(uri));
}

export function getCurrentFeaturesUri() {
  const uri = localStorage.getItem('current_features_uri');
  if (uri) {
    return JSON.parse(uri);
  }
  return null;
}

export function removeCurrentFeaturesUri() {
  localStorage.removeItem('current_features_uri');
}

export function persistSearchRequest(obj) {
  localStorage.setItem('request', JSON.stringify(obj));
}

export function getSearchRequest() {
  const obj = localStorage.getItem('request');
  if (obj) {
    return JSON.parse(obj);
  }
  return null;
}

export function removeSearchRequest() {
  localStorage.removeItem('request');
}

export function getCurrentApiURL() {
  const istartup = getAtStartup();
  if(istartup) {
    const apiUrl = localStorage.getItem('api_url');
    if (urlNotEmpty(apiUrl)) {
      const api = JSON.parse(apiUrl);
      if (api.endsWith('/v1/')) {
        return api.split('/v1')[0];
      } else {
        return  api;
      }
    }
  } 
  return API_URL;
}

export function isPrivateServer() {
  const istartup = getAtStartup();
  if(istartup) {
    const apiUrl = localStorage.getItem('api_url');
    if (urlNotEmpty(apiUrl)) {
      return true;
    }    
  }

  return false;
}

export function getPrivateDomain() {
  const apiUrl = localStorage.getItem('api_url');
  if (urlNotEmpty(apiUrl)) {
     const privateDomain = localStorage.getItem('private_domain');
     if (privateDomain !== null && privateDomain !== undefined) {
        return privateDomain;
     }
  }
  return null;
}

export function isFieldAlreadyExist(f, parent): boolean {
  const fields = parent.fields;
  for (const field of fields) {
    if (field.name === f.name) {
      return true;
    }
    if (field.label === f.label) {
      return true;
    }
  }
  return false;
}

export function getCurrentDomain() {
  const istartup = getAtStartup();
  if(istartup) {
    const apiUrl = localStorage.getItem('api_url');
    if (urlNotEmpty(apiUrl)) {
      const api = JSON.parse(apiUrl);
      let domain = '';
      if (api.endsWith('/v1/')) {
        const el = api.split('/v1')[0];
        domain = el.split('//')[1];
      } else {
        domain = api.split('//')[1];
      }
      return domain;
    }
  }
  return DOMAIN;
}

export function isCollaborator(user): boolean {
  const userType = user['user_type'];
  if (userType !== null && userType !== undefined) {
    if (userType === 'collaborator') {
      return true;
    }
  } else {
    const managerId = user['manager_id'];
    if (managerId !== null && managerId !== undefined) {
      if (managerId.length > 0) {
        return true;
      }
    }
  }
  return false;
}

export function getPrivateApiAURL() {
  const istartup = getAtStartup();
  if(istartup){
    var apiUrl = localStorage.getItem('api_url');
    if(urlNotEmpty(apiUrl)){
      const api = JSON.parse(apiUrl);
      if (api.endsWith('/v1/')) {
        return api;
      } else {
        return  api + '/v1/';
      }
    }   
    localStorage.setItem('at_startup', JSON.stringify(false));
  }
  
  return URL;
}

function urlNotEmpty (apiUrl: string){
  return apiUrl !== null && apiUrl !== undefined && apiUrl !== 'undefined' && apiUrl.length !== 0 && apiUrl !== "";
}

export function removeToken() {
  localStorage.removeItem('token');
}

export function persistApiURL(apiUrl, atStartup) {
  localStorage.setItem('api_url', JSON.stringify(apiUrl.trim()));
  localStorage.setItem('at_startup', JSON.stringify(atStartup));
}

export function getApiAURL() {
  const apiUrl = localStorage.getItem('api_url');
  const istartup = getAtStartup();
  if (urlNotEmpty(apiUrl)) {
    const api = JSON.parse(apiUrl);
    return api;
  }
  return '';
}

export function getAtStartup(): boolean{
  return JSON.parse(localStorage.getItem('at_startup') || 'false') as boolean;
}

export function removeApiURL() {
  localStorage.removeItem('api_url');
}

export function isTokenValid(token: Token): boolean {
  const expireAt = token.expires_at;
  const currentDate = new Date();
  const expireDate = new Date(expireAt);
  return compareDates(expireDate, currentDate) > 0;
}

function compareDates(date1: Date, date2: Date): number {
  // With Date object we can compare dates them using the >, <, <= or >=.
  // The ==, !=, ===, and !== operators require to use date.getTime(),
  // so we need to create a new instance of Date with 'new Date()'
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  // Check if the dates are equal
  const same = d1.getTime() === d2.getTime();
  if (same) {
    return 0;
  }

  // Check if the first is greater than second
  if (d1 > d2) {
    return 1;
  }

  // Check if the first is less than second
  if (d1 < d2) {
    return -1;
  }
}

export function persistUser(user) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function getUser() {
  let user = localStorage.getItem('user');
  if (user !== undefined && user !== 'undefined' && user !== null) {
    return JSON.parse(user);
  } else {
    user = getAdminInfo();
    return user;
  }
}

export function removeUser() {
  localStorage.removeItem('user');
}

export function logoutUser() {
  const crs = getCRSSetting();
  const istartup = getAtStartup();
  const url = getApiAURL();
  localStorage.clear();
  if(crs !== null && crs !== undefined) {
    localStorage.setItem('crs', crs);
  }
  
  persistApiURL(url, istartup);
}

export function persistProject(project) {
  localStorage.setItem('project', JSON.stringify(project));
}

export function getProject() {
  const p = localStorage.getItem('project');
  return JSON.parse(p);
}

export function removeProject() {
  localStorage.removeItem('project');
}

export function persistTemplate(template) {
  const t = localStorage.getItem('template');
  if (t !== null && t !== undefined) {
    localStorage.removeItem('template');
  }
  localStorage.setItem('template', JSON.stringify(template));
}

export function getTemplate() {
  const t = localStorage.getItem('template');
  return JSON.parse(t);
}

export function removeTemplate() {
  removeFieldset();
  removeFieldToShowOnMap();
  removeActiveFieldset();
  removeCurrentFieldset();
  removeParentFieldset();
  removeSelectedFieldIndex();
  localStorage.removeItem('template');
}

export function persistTemplates(templates) {
  const t = localStorage.getItem('templates');
  if (t !== null && t !== undefined) {
    localStorage.removeItem('templates');
  }
  localStorage.setItem('templates', JSON.stringify(templates));
}

export function getTemplates() {
  const t = localStorage.getItem('templates');
  return JSON.parse(t);
}

export function removeTemplates() {
  removeFieldset();
  removeFieldToShowOnMap();
  removeActiveFieldset();
  removeCurrentFieldset();
  removeParentFieldset();
  removeSelectedFieldIndex();
  localStorage.removeItem('templates');
}

export function persistFieldset(fieldset) {
  localStorage.setItem('fieldset', JSON.stringify(fieldset));
}

export function getFieldset() {
  const f = localStorage.getItem('fieldset');
  return JSON.parse(f);
}

export function removeFieldset() {
  localStorage.removeItem('fieldset');
}

export function persistGeomFormToShow(geomFormToShow) {
  localStorage.setItem('geom_form_to_show',JSON.stringify(geomFormToShow));
}

export function getGeomFormToShow() {
  const geom = localStorage.getItem('geom_form_to_show');
  return JSON.parse(geom);
}

export function removeGeomFormToShow() {
  localStorage.removeItem('geom_form_to_show');
}

export function persistActiveFieldset(fieldset) {
  localStorage.setItem('activeFieldset', JSON.stringify(fieldset));
}

export function getActiveFieldset() {
  const f = localStorage.getItem('activeFieldset');
  return JSON.parse(f);
}

export function removeActiveFieldset() {
  localStorage.removeItem('activeFieldset');
}

export function setIfRootFieldsetSelected(value) {
  localStorage.setItem('rootFieldsetActive', value);
}

export function getIfRootFieldsetSelected(value) {
  return localStorage.getItem('rootFieldsetActive');
}

export function addOrUpdateField(result) {
  const activeFieldset = getActiveFieldset();
  if (result.action === 'Create') {
    activeFieldset.fields.push(result.field);
  } else  if (result.action === 'Update') {
    activeFieldset.fields.splice(this.activeFieldIndex, 1, result.field);
  }
}

export function persistCurrentFieldset(fieldset) {
  localStorage.setItem('currentFieldset', JSON.stringify(fieldset));
}

export function getCurrentFieldset() {
  const cf = localStorage.getItem('currentFieldset');
  return JSON.parse(cf);
}

export function removeCurrentFieldset() {
  localStorage.removeItem('currentFieldset');
}

export function persistParentFieldset(fieldset) {
  localStorage.setItem('parentFieldset', JSON.stringify(fieldset));
}

export function getParentFieldset() {
  const cf = localStorage.getItem('parentFieldset');
  return JSON.parse(cf);
}

export function removeParentFieldset() {
  localStorage.removeItem('parentFieldset');
}

export function persistSelectedFieldIndex(index) {
  localStorage.setItem('selectedIndex', index);
}

export function getSelectedFieldIndex() {
  return Number(localStorage.getItem('selectedIndex'));
}

export function removeSelectedFieldIndex() {
  localStorage.removeItem('selectedIndex');
}

export function persistCRSSetting(crs) {
  localStorage.setItem('crs', crs);
}

export function getCRSSetting() {
  const crs = localStorage.getItem('crs');
  if(crs === 'undefined' || crs === null || crs === undefined || crs === '' || crs === 'Geodetic'){
    return undefined;
  }
  return crs;
}

export function removeCSRSetting() {
  localStorage.removeItem('crs');
}

export function persistUTMZone(utmzone) {
  localStorage.setItem('utmzone', utmzone);
}

export function getUTMZone() {
  const utmzone = localStorage.getItem('utmzone');
  if(utmzone === 'undefined' || utmzone === null || utmzone === undefined || utmzone === '' || utmzone === 'Geodetic'){
    return undefined;
  }
  return utmzone;
}

export function removeUTMZone() {
  localStorage.removeItem('utmzone');
}

export function saveTemplateFieldsNameAndTypes(fieldsNameAndType) {
  localStorage.setItem('fields_name_and_type', JSON.stringify(fieldsNameAndType));
}

export function getTemplateFieldsNameAndTypes(){
  return JSON.parse(localStorage.getItem('fields_name_and_type'));
}

export function saveTemplateFieldsNameAndValues(fieldsNameAndValue) {
  localStorage.setItem('fields_name_and_value', JSON.stringify(fieldsNameAndValue));
}

export function getTemplateFieldsNameAndValues() {
  localStorage.getItem('fields_name_and_value');
}

export function saveTemplateMenu(menu) {
  localStorage.setItem('templateMenu', JSON.stringify(menu));
}

export function getTemplateMenu() {
  const resp = JSON.parse(localStorage.getItem('templateMenu'));
  return resp;
}

export function persistLastMenu(menu) {
  localStorage.removeItem('lastTemplateMenu');
  localStorage.setItem('lastTemplateMenu', JSON.stringify(menu));
}

export function getlastMenu() {
  return JSON.parse(localStorage.getItem('lastTemplateMenu'));
}

export function persistChoosenCRSType(choosenCRSType) {
  localStorage.setItem('choosenCRSType', JSON.stringify(choosenCRSType));
}

export function getChoosenCRSType() {
  const crs = localStorage.getItem('choosenCRSType');
  if (crs === undefined || crs === 'undefined' || crs === null) {
    return null;
  }
  return JSON.parse(crs);
}

export function removeChoosenCRSType() {
  return localStorage.removeItem('choosenCRSType');
}

export function persistSearchObject(objectSend) {
  localStorage.setItem('objectSend', JSON.stringify(objectSend));
}

export function getSearchObject() {
  return JSON.parse(localStorage.getItem('objectSend'));
}

export function removeSearchObject() {
  localStorage.removeItem('objectSend');
}

export function persistFieldToShowOnMap(objectSend) {
  localStorage.setItem('fields_to_show', JSON.stringify(objectSend));
}

export function getFieldToShowOnMap() {
  const fieldsObj  = localStorage.getItem('fields_to_show');
  if (fieldsObj !== null && fieldsObj !== undefined) {
    return JSON.parse(fieldsObj);
  }
  return null;
}

export function removeFieldToShowOnMap() {
  localStorage.removeItem('fields_to_show');
}

export function getTokenFromUrl(tok) {
  const half = location.href.split('?' + tok + '=');
  if (half.length > 1) {
    return half[1];
  }
  return null;
}

export function persistAdminInfo(admin) {
  localStorage.setItem('adminInfo', JSON.stringify(admin));
}

export function removeAdminInfo() {
  localStorage.removeItem('adminInfo');
}

export function getAdminInfo() {
  return JSON.parse(localStorage.getItem('adminInfo')) || {};
}

export function persistFeaturesSet(features) {
  localStorage.setItem('featuresSet', JSON.stringify(features));
}

export function getFeaturesSet() {
  return JSON.parse(localStorage.getItem('featuresSet')) || [];
}

export function persisPrevImages(prevImages) {
  localStorage.setItem('prevImages', JSON.stringify(prevImages));
}

export function getPrevImages() {
  return JSON.parse(localStorage.getItem('prevImages')) || [];
}

export function persistProjectTemplates(templates) {
  localStorage.setItem('templates', JSON.stringify(templates));
}

export function getProjectTemplates() {
  return JSON.parse(localStorage.getItem('templates')) || {};
}

export function getAdminToken() {
  return JSON.parse(localStorage.getItem('admintoken'));
}

export function removeAdminToken() {
  localStorage.removeItem('admintoken');
}

export function persistAdminAuthToken(token) {
  localStorage.setItem('admintoken', JSON.stringify(token));
}

export function persistMarxactAuthToken(token) {
  localStorage.setItem('marxacttoken', JSON.stringify(token));
}

export function removeSpecialCharacters(str) {
  if (!str) {
    return '';
  }
  return str.replace(/[\W_]/g, '_');
}

export function getMarxactAuthToken() {
  return JSON.parse(localStorage.getItem('marxacttoken')) || '';
}

export function removeMarxactPassword(token) {
  localStorage.removeItem('marxacttoken');
}

export function persistMarxactPassword(password) {
  localStorage.setItem('marxactpassword', password);
}

export function getMarxactPassword() {
  return localStorage.getItem('marxactpassword') || '';
}

export function removeMarxactUsername(token) {
  localStorage.removeItem('marxactpassword');
}

export function persistMarxactUsername(username) {
  localStorage.setItem('marxactusername', username);
}

export function getMarxactUsername() {
  return localStorage.getItem('marxactusername') || '';
}

export function removeMarxactUserConnected(token) {
  localStorage.removeItem('marxactuserconnected');
}

export function persistMarxactUserConnected() {
  localStorage.setItem('marxactuserconnected', 'true');
}

export function getMarxactUserConnected() {
  return localStorage.getItem('marxactuserconnected') || false;
}

export function removeMarxactAuthToken() {
  localStorage.removeItem('marxacttoken');
}

export function getIcon(letter) {

}
