import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable} from '@angular/material/table';
import { FeatureService } from 'src/app/services/feature.service';
import { getTemplate, getProject, getToken, saveTemplateFieldsNameAndTypes,
  getTemplateFieldsNameAndTypes, getChoosenCRSType, url, getCurrentDomain,persistLastMenu, removeSearchObject, persistSearchValue, removeSearchValue, getSearchValue, getSearchObject, getCurrentFeaturesUri, removeCurrentFeaturesUri, persistCurrentFeaturesUri, getAdminToken, removeSearchRequest } from 'src/app/share/utils';
import { ExportDataCriteriaDialogComponent } from '../dialogs/export-data-criteria-dialog/export-data-criteria-dialog.component';
import { MatDialog, MatPaginator, MatSort, MatDialogConfig } from '@angular/material';
import { Template } from 'src/app/share/template';
import { FeatureSchema } from 'src/app/share/schema/schema';
import { AttributeSet, Attribute } from 'src/app/share/feature/attributes';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AttributesetarrayDialogComponent } from '../dialogs/attributesetarray-dialog/attributesetarray-dialog.component';
import { GeomTypeCoordinatesDialogComponent } from '../dialogs/geom-type-coordinates-dialog/geom-type-coordinates-dialog.component';
import { MediaShowingDialogComponent } from '../dialogs/media-showing-dialog/media-showing-dialog.component';
import { EditFeatureValueComponent } from '../dialogs/edit-feature-value/edit-feature-value.component';
import { ReportsService } from 'src/app/services/reports.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { StoreService, IMPORT_DATA_MODE } from 'src/app/services/store.service';
import * as $ from 'jquery';
import { TitleCasePipe } from '@angular/common';
import { ViewDataCriteriaDialogComponent } from '../dialogs/view-data-criteria-dialog/view-data-criteria-dialog.component';
import { EditGeomOrCoordValuesComponent } from '../dialogs/edit-geom-or-coord-values/edit-geom-or-coord-values.component';

const ELEMENT_DATA: AttributeSet[] = [];

const COORDINATES = 'coordinates';
const ATTRIBUTESETARRAY = 'arrayattributeset';

const _SELECT = '_select';

@Component({
  selector: 'app-feature-data',
  templateUrl: './feature-data.component.html',
  styleUrls: ['./feature-data.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class FeatureDataComponent implements OnInit, AfterViewInit, AfterContentInit {
  GEOMETRY_TYPE = 'Geometry_Type';
  searchValue: string;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  templateName: string;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatTable, {static: false}) featuretable: MatTable<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  template: Template;
  featureSchema: FeatureSchema;
  initHeaders: any[];
  arrayHeadersAndSize: any[];
  attHeaders: any[];
  tableHeaders: any[];
  schemaHeader: any[];
  allCoordinates: any[];
  featureAttrib: any[] = [];
  instances: any[] = [];
  allArrayAttributeSet: any[] = [];
  allArrayAttributeSetElemment: any[] = [];
  allCoordinatesFields: any[] = [];
  allCoordinatesFieldsElemment: any[] = [];
  linksArray: any[];
  ColumsValues: any[] = [];
  columnsValues: any[];
  advancedSearchCriteria: any;
  aggregation: any[] = [];
  fromDate: any;
  toDate: any;
  sortedObject: any;
  featureData: any[] = [];
  nbrPage: number;
  fetchMore: boolean;
  searchCriteria: any;
  numindex: number;
  templateId: string;
  nextLink: string;
  prevLink: string;
  pageFeatures: number;
  totalFeatures: number;
  featuresSelected: any[];
  projectionObject: any;
  operators: any[];
  reportType: string;
  doingReport: boolean;
  metadata: object;
  more: boolean;
  resultsLength = 0;
  initValue = 0;
  uri: string;
  prevDisabled: boolean;
  nextDisabled: boolean;
  pages = 0;
  allPages: string[];
  selectedPage: string;
  activePage: number;
  templateChanged: string;
  fields_and_name_type: Object;

  message = '';
  showMessage = false;

  constructor(
    private featureService: FeatureService,
    private dialog: MatDialog,
    private titlecasePipe: TitleCasePipe,
    public store: StoreService,
    private reportService: ReportsService
  ) {

  }

  ngAfterContentInit() {
    const template = getTemplate();
    this.updateTemplateFeature(template);
    this.store.template.subscribe(res => {
      this.templateChanged += this.templateChanged ? ' again' : '';
      this.template = res;
      this.initElements(res);
      this.selection.clear();
      this.selection = new SelectionModel<Element>(true, []);
      this.updateTemplateFeature(res);
    });

    this.store.customExport.subscribe(res => {
      if(res) {
        this.showExportDialog();
      }
    })
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    const template = getTemplate();
    this.columnsValues = [];
    this.searchValue = '';
    const searchVal = getSearchValue();
    if (searchVal) {
      this.searchValue = searchVal;
      this.initElements(this.template);
      this.searchText(searchVal);
      return;
    }
    const searchObject = getSearchObject();
    if (searchObject && searchObject['template'] === template["id"]) {
      this.initElements(this.template);
      this.submitSeletedItems2(searchObject);
      return;
    } else {
      this.columnsValues = [];
    }

    const getSavedUri = getCurrentFeaturesUri();
    if (getSavedUri) {
      this.initElements(this.template);
      this.loadFeatures(getSavedUri);
      return;
    }
  }

  initElements(template) {
    if (template) {
      this.templateName = template.name;
    }

    this.nbrPage = 40;
    this.initValue = 0;
    this.fetchMore = false;
    this.attHeaders = [];
    this.tableHeaders = [];
    this.featureData = [];
    this.instances = [];
    this.allCoordinates = [];
    this.allCoordinatesFields = [];
    this.allCoordinatesFieldsElemment = [];
    this.allArrayAttributeSet = [];
    this.allArrayAttributeSetElemment = [];
    this.searchCriteria = {};
    this.numindex = 0;
    this.resultsLength = 100;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.doingReport = false;
    this.allPages = [];
    this.pages = 0;
    this.activePage = 0;
    this.selectedPage = '';
  }

  updateTemplateFeature(template) {

    if (template === null || template === undefined) {
      return;
    }
    this.initElements(template);
    this.uri = 'templates/' + template.id + '/' + 'features?perPage=' + this.nbrPage;
    removeSearchObject();
    removeSearchValue();
    removeCurrentFeaturesUri();
    this.store.features = null;
    this.loadFeatures(this.uri);
  }

  loadFeatures(uri) {
    const project = getProject();
    let token = getToken();
    if(token === null || token === undefined) {
      token = getAdminToken();
    }
    this.store.showLoading();
    this.featureService.getFeatures(token, uri, project).subscribe(
      res => {
        persistCurrentFeaturesUri(uri);
        this.updateDataFormServer(res);
        this.store.hideLoading();
      },
      error => {
        const msg = error.message ? error.message : error;
        this.showErrorMessage(msg);
        this.store.hideLoading();
      });
  }

  searchText(value) {
    const project = getProject();
    const token = getToken();
    this.store.showLoading();
    this.featureService.textSearch(token, this.template, project, value).subscribe(
      res => {
        this.store.features = res['instances'];
        removeSearchValue();
        removeSearchObject();
        removeCurrentFeaturesUri();
        persistSearchValue(value);
        this.updateDataFormServer(res);
        this.store.hideLoading();
      },
      error => {
        const msg = error.message ? error.message : error;
        this.showErrorMessage(msg);
        this.store.hideLoading();
      });
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  isMorePages(): boolean {
    return this.pages > 1;
  }

  openPage(item) {
    let linkValue = '';
    if (this.nextLink !== null && this.nextLink !== undefined) {
      linkValue = this.nextLink;
    } else if (this.prevLink !== null && this.prevLink !== undefined) {
      linkValue = this.prevLink;
    }

    if (linkValue !== '') {
      for (let i = 0; i < 4; i++) {
        linkValue = linkValue.substring(1);
      }

      const links = linkValue.split('?');
      const values = links[1].split('&');
      const vals = values[0].split('=');
      const uri = links[0] + '?' + vals[0] + '=' + item.trim() + '&' + values[1];

      this.loadFeatures(uri);
    }

  }

  /**
   * updating the data in the table after any request for fetching or searching data.
   */
  updateDataFormServer(features) {
    this.featuresSelected = [];
    this.allPages = [];
    const template = getTemplate();
    if (template !== null && template !== undefined) {
      this.template = template;
      this.featureSchema = template.feature;
      this.saveTemplateFieldsNameAndType(template);
    }
    const searchObject = getSearchObject();
    if (searchObject === null
            || searchObject === undefined
            || searchObject['template'] !== template["id"]) {
      this.columnsValues = [];
    }

    this.metadata = features.metadata;
    this.featureData = [];
    this.resultsLength = features.length;

    this.instances = features.instances;

    const headers = this.createHeaderArrayWithinTemplate(template);
    this.tableHeaders = this.createTableHeaderArrayWithinTemplate(template);
    const duplicateHeaders = this.findDuplicates(this.tableHeaders);
    if(duplicateHeaders && duplicateHeaders.length > 0) {

      const headersAsString = duplicateHeaders.join(', ');
      this.showErrorMessage(`The form contains duplicate Label [${headersAsString}]`);
      setTimeout(() => {
      }, 1000)

      return;
    }

    this.attHeaders = headers;
    if (this.instances.length > 0) {
      const nextUri = features.metadata.uris.next;
      const prevUri = features.metadata.uris.prev;

      this.pages = features.metadata.pages;
      this.activePage = features.metadata.page;

      if (this.pages > 0) {
        for (let v = 1; v <= this.pages; v++) {
          this.allPages.push(v + ' ');
        }
      }

      const data = this.createFeatureData();

      let listGeometryHeader = null;
      if (this.instances[0]['geometry'] !== null && this.instances[0]['geometry'] !== undefined) {
        listGeometryHeader = Object.keys(this.instances[0]['geometry']);
      }
      if (this.columnsValues.length > 0) {
        this.displayedColumns = [];
        this.displayedColumns.push(_SELECT);
        const index = this.columnsValues.indexOf('Geometry Type');
        if (listGeometryHeader !== null && listGeometryHeader !== undefined && index !== -1) {
          this.displayedColumns.push(this.GEOMETRY_TYPE);
        }
        for (const b of this.columnsValues) {
          const val = this.tableHeaders[this.attHeaders.indexOf(b)];
          if (val !== 'Geometry Type') {
            this.displayedColumns.push(val);
          }
        }
      } else if (this.tableHeaders !== null && this.tableHeaders !== undefined && this.tableHeaders.length > 0) {
        this.displayedColumns = [];
        this.displayedColumns.push(_SELECT);
        if (listGeometryHeader !== null && listGeometryHeader !== undefined) {
          this.displayedColumns.push(this.GEOMETRY_TYPE);
        }
        for (const h of this.tableHeaders) {
          this.displayedColumns.push(h);
        }
      }

      const value = (features.metadata.page - 1) * this.nbrPage;
      if (features.metadata.page === 1 && features.instances.length > 0) {
        this.initValue = 1;
      } else if (features.instances.length === 0) {
        this.initValue = 0;
      } else {
        this.initValue = value + 1;
      }

      if ((nextUri === null || nextUri === undefined || nextUri === '')) {
        this.nextDisabled = true;
      } else {
        this.nextDisabled = false;
        this.nextLink = nextUri;
      }

      if (prevUri === null || prevUri === undefined || prevUri === '') {
        this.prevDisabled = true;
      } else {
        this.prevDisabled = false;
        this.prevLink = prevUri;
      }
      this.pageFeatures = features.metadata.page_count + value;
      this.totalFeatures = features.metadata.total_count;

      let $items = $('.bleuPage');
      const classHighlight = 'selectedPage';
      $items.removeClass(classHighlight);
      this.selectedPage = this.activePage + ' ';
      this.dataSource.data = data;
      this.featuretable.renderRows();
    } else {
      this.initValue = 0;
      this.pageFeatures = 0;
      this.totalFeatures = 0;

      this.displayedColumns = [];
      this.dataSource.data = [];
      this.featuretable.renderRows();
    }
  }

  findDuplicates(headers: any[]): any[] {
    let duplicates: any[] = [];
    headers.forEach((value, index) => {
        if (headers.indexOf(value, index + 1) > -1 && duplicates.indexOf(value) === -1) {
            duplicates.push(value);
        }
    });
    return duplicates;
  }

  loadPrevFeatures() {
    if (!this.prevLink || this.prevLink === '') {
      return;
    }

    for (let i = 0; i < 4; i++) {
      this.prevLink = this.prevLink.substring(1);
    }

    this.loadFeatures(this.prevLink);
  }

  loadNextFeatures() {
    if (this.nextLink === undefined || this.nextLink === null || this.nextLink === '') {
      return;
    }

    for (let i = 0; i < 4; i++) {
      this.nextLink = this.nextLink.substring(1);
    }

    this.loadFeatures(this.nextLink);
  }

  removeSelectedFeature() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`Please, this operation is only available for active  ${projectClass}`);
      return;
    }
    const size = this.selection.selected.length;
    if (size <= 0) {
      this.showErrorMessage('No records selected. Please choose records to delete');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: 'These records will be deleted on the server.',
        title: 'Delete ' + size + ' records ?'
      }
    });
    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        this.store.showLoading();
        const ids = [];
        for (const feature of this.selection.selected) {
          ids.push(feature.id);
        }
        const project = getProject();
        this.featureService.deleteFeatures(ids, this.template, project, token).subscribe(
          res => {
            this.store.features = null;
            this.loadFeatures(this.uri);
            this.showSuccessMessage(size + ' record(s) deleted');
            this.selection.clear();
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.showErrorMessage('Error while deleting the records' + errmes);
          }
        );
      }
    });
  }

  showMessageDialog(msg) {
    this.message = msg;
    this.store.showLoading();
    setTimeout(() => {
      this.store.hideLoading();
    }, (10000));
  }

  createFeatureData(): any[] {
    const data = [];
    this.instances.forEach(inst => {
      let dataItem = this.createDataArray(inst['attributes'], '');
      dataItem['id'] = inst['id'];
      if (inst['geometry'] !== null && inst['geometry'] !== undefined) {
        const geomDataItem = this.createGeometryDataArray(inst['geometry']);
        if (geomDataItem !== null && geomDataItem !== undefined) {
          dataItem = Object.assign(geomDataItem, dataItem);
        }
      }
      data.push(dataItem);
    });
    return data;
  }

  createTableHeaderArrayWithinTemplate(template: Template): string[] {
    let result = [];
    const form = template.form;
    if(form) {
      const fields = form.fields;
      for (const field of fields) {
        const attname = field['label'];
        if (field['_class'] === 'fieldset') {
          result = result.concat(this.createTableHeaderWithinAttributeSet(field, attname));
        } else {
          result.push(attname);
        }
      }
    }
    return result;
  }

  createTableHeaderWithinAttributeSet(fieldset, parent): string[] {
    let obj = [];
    for (const field of fieldset.fields) {
      if (field._class === 'fieldset') {
        const name = `${parent}.${field.label}`;
        obj = obj.concat(this.createTableHeaderWithinAttributeSet(field, name));
      } else if (field._class === 'arrayfieldset') {
        const name = `${parent}.${field.label}`;
        obj.push(name);
      } else {
        const attname = `${parent}.${field.label}`;
        obj.push(attname);
      }
    }
    return obj;
  }


  createHeaderArrayWithinTemplate(template: Template): string[] {
    let result = [];
    const schema = template.feature;
    const attributes = schema.attributes;
    for (const attribute of attributes) {
      const attname = attribute['name'];
      if (attribute['_class'] === 'attribute') {
        result.push(attname);
      } else if (attribute['_class'] === 'arrayattributeset') {
        result.push(attname);
      } else {
        result = result.concat(this.createHeaderWithinAttributeSet(attribute, attname));
      }
    }
    return result;
  }

  createHeaderWithinAttributeSet(attributeset, parent): string[] {
    let obj = [];
    for (const attribute of attributeset.attributes) {
      if (attribute._class === 'attribute') {
        const attname = `${parent}.${attribute.name}`;
        obj.push(attname);
      } else if (attribute._class === 'arrayattributeset') {
        const name = `${parent}.${attribute.name}`;
        obj.push(name);
      } else {
        const name = `${parent}.${attribute.name}`;
        obj = obj.concat(this.createHeaderWithinAttributeSet(attribute, name));
      }
    }
    return obj;
  }

  createGeometryDataArray(geometry: any): any {
    return {
      Geometry_Type: geometry['type'],
      coordinates: geometry[COORDINATES]
    };
  }

  createDataArray(attributeset: any, parent = ''): any {
    let result = {};
    const keys = Object.keys(attributeset);
    for (const key of keys) {
      const absAttribute = attributeset[key];
      const path = parent === '' ? key : `${parent}.${key}`;
      const itemIndex = this.attHeaders.findIndex(header => header === path, 0);
      if (absAttribute instanceof Object) {
        if (absAttribute instanceof Array) {
          if(itemIndex >= 0) {
            result[this.tableHeaders[itemIndex]] = absAttribute;
          }
        } else {
          const tempResult = this.createDataArray(attributeset[key], path);
          result = Object.assign(result, tempResult);
        }
      } else {
        if(itemIndex >= 0) {
          result[this.tableHeaders[itemIndex]] = attributeset[key];
        }

      }
    }

    return result;
  }

  getValue(value: string) {
    $('.has-clear input[type="text"]').on('input propertychange', function() {
      const $this = $(this);
      const visible = Boolean($this.val());
      $this.siblings('.form-control-clear').toggleClass('hidden', !visible);
    }).trigger('propertychange');

    $('.form-control-clear').click(function() {
      $(this).siblings('input[type="text"]').val('')
        .trigger('propertychange').focus();
    });
  }

  applyFilter() {
    const value = this.searchValue.trim().toLowerCase();

    if (value && value !== '') {
      this.searchText(value);
    } else {
      this.updateTemplateFeature(this.store.template.getValue());
    }
  }

  refreshData() {
    this.searchValue = '';
    this.columnsValues = [];
    this.updateTemplateFeature(this.store.template.getValue());
  }

  displayItemValue(column, value) {
    column = this.attHeaders[this.tableHeaders.indexOf(column)];
    const fieldsAndType = this.fields_and_name_type;
    const type = fieldsAndType[column];
    if (type === COORDINATES) {
      return COORDINATES;
    } else if (type === ATTRIBUTESETARRAY) {
      return this.displayAttSetArrayValue(column);
    } else {
      const data = this.checkFiles(value);
      if (this.checkIfIsUrl(data)) {
        return '';
      } else if (type === 'date' && this.checkIfDate(data, name)) {
        return data.split('T')[0];
      }
      return data;
    }
  }

  displayAttSetArrayValue(column) {
    const index = column.indexOf('.');
    if (index > 0) {
      const values = column.split('.');
      return values[values.length - 1];
    }
    return column;
  }

  checkIfColumnIsGeometry(column) {
    return column === this.GEOMETRY_TYPE;
  }

  checkIfCoordinatesField(column) {
    const fieldsAndType = this.fields_and_name_type;
    const i = this.tableHeaders.indexOf(column);
    const type = fieldsAndType[this.attHeaders[i]];
    return type === COORDINATES;
  }

  checkIfAttributesetArray(column) {
    const fieldsAndType = this.fields_and_name_type;
    const i = this.tableHeaders.indexOf(column);
    const type = fieldsAndType[this.attHeaders[i]];
    return type === ATTRIBUTESETARRAY;
  }

  selectFeature(event, element): void {
    this.selection.toggle(element);
    const feature = this.instances.find(f => f.id === element.id);
    if (event.checked) {
      if (this.store.features) {
        this.store.features.push(feature);
      } else {
        this.store.features = [feature];
      }
    } else {
      if (this.store.features) {
        this.store.features = this.store.features.filter(f => f.id !== element.id);
      }
    }
  }

  openMediaShowingDialog(event, link) {
    event.preventDefault();
    const medialink = this.checkFile(link);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      medialink
    };

    const dialogRef = this.dialog.open(MediaShowingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
    event.stopPropagation();
  }

  isSelectFeatureHeader(column) {
    return column === '_select';
  }

  isSelectFeatureData(column, element) {
    return column === '_select';
  }

  openAttributesetArrayDialog(column, element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '700px';
    dialogConfig.height = 'auto';

    const id = element['id']
    let feature = null;
    let featureIndex = -1;
    if (id !== null && id !== undefined) {
      feature = this.instances.filter(f => f.id === id)[0];
      featureIndex = this.instances.indexOf(feature);
    }
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      attributesetarray: element[column],
      path: column,
      attname: this.attHeaders[this.tableHeaders.indexOf(column)],
      feature,
      column
    };
    const attsetarrayRef = this.dialog.open(AttributesetarrayDialogComponent, dialogConfig);
    attsetarrayRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        if  (featureIndex >= 0) {
          this.instances[featureIndex] = result;
          this.dataSource.data = this.createFeatureData();
          this.featuretable.renderRows();
        }
      }
    });
  }

  displayCoordinates(column, element) {
    const geomType = element[column];
    const coordinates = element[COORDINATES];
    let gpscoord = false;
    if (coordinates) {
      gpscoord = !(coordinates[0] instanceof Object);
    }
    this.openCoordinateDialog(geomType, coordinates, gpscoord, element);
  }

  displayEditGPSCoordinates(column, element) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`Please, this operation is only available for active  ${projectClass}`);
      return;
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    const id = element['id']
    let feature = null;
    let featureIndex = -1;
    if (id !== null && id !== undefined) {
      feature = this.instances.filter(f => f.id === id)[0];
      featureIndex = this.instances.indexOf(feature);
    }
    dialogConfig.autoFocus = true;

    const coordinates = element[column];
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      dialogConfig.data = {
        feature,
        column,
        isCoordAttributes: true,
        coordAttributes: coordinates
      };

      const dialogRef = this.dialog.open(EditGeomOrCoordValuesComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result !== null && result !== undefined) {
          if  (featureIndex >= 0) {
            this.instances[featureIndex] = result;
            this.dataSource.data = this.createFeatureData();
            this.featuretable.renderRows();
          }
        }
      });
    } else {
      this.showErrorMessage('The coordinates field is empty');
    }
  }

  displayGPSCoordinates(column, element) {
    const geomType = element[column];
    const coordinates = element[COORDINATES];
    let gpscoord = false;
    if (coordinates) {
      gpscoord = !(coordinates[0] instanceof Object);
    }
    this.openCoordinateDialog(null, geomType, gpscoord);
  }

  openCoordinateDialog(geometryType, coordinates, gpscoord = false, element = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    let feature = null;
    if(element)
      feature = this.instances.find(f => f.id === element.id);
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      feature: feature,
      element: element,
      geometry_type: geometryType,
      gpscoord,
      coordinates
    };

    const dialogRef = this.dialog.open(GeomTypeCoordinatesDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  getAttributeType(attributes, column): string {
    let type = '';

    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];

        if(attribute._class === 'attributeset'){
          type = this.getAttributeType(attribute.attributes, column);
          if(type !== '')
            return type;
        }

        if(attribute.name === column){
          type = attribute.type;
          return type;
        }
      }
    }
    return type;
  }

  valueExistInArarray(value, array): boolean{
    if(array.length <= 0){
      return false;
    }

    for(let i = 0; i < array.length; i++){
      if(array[i] === value)
        return true;
    }

    return false;
  }

  displayEditCoordinates(column, element){
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`Please, this operation is only available for active  ${projectClass}`);
      return;
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    const id = element['id']
    let feature = null;
    let featureIndex = -1;
    if (id !== null && id !== undefined) {
      feature = this.instances.filter(f => f.id === id)[0];
      featureIndex = this.instances.indexOf(feature);
    }
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      feature,
      isCoordAttributes: false,
      coordAttributes: null
    };

    const dialogRef = this.dialog.open(EditGeomOrCoordValuesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        if  (featureIndex >= 0) {
          this.instances[featureIndex] = result;
          this.dataSource.data = this.createFeatureData();
          this.featuretable.renderRows();
        }
      }
    });
  }

  openEditFeatureValue(column, element) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`Please, this operation is only available for active  ${projectClass}`);
      return;
    }
    const label = column;
    column = this.attHeaders[this.tableHeaders.indexOf(column)];
    const dialogConfig = new MatDialogConfig();
    const columnValues = column.split('.');
    let newColumn = '';
    if(columnValues.length > 0)
      newColumn = columnValues[columnValues.length - 1];
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    const id = element['id']
    let feature = null;
    let featureIndex = -1;
    if (id !== null && id !== undefined) {
      feature = this.instances.filter(f => f.id === id)[0];
      featureIndex = this.instances.indexOf(feature);
    }

    const attributes = this.template.feature.attributes;

    let type = this.getAttributeType(attributes, newColumn);

    const value = element[label];
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      column,
      feature,
      element,
      value,
      type
    };
    if(type === 'file' || type === undefined || type === '')
      return;

    const dialogRef = this.dialog.open(EditFeatureValueComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        if (result.event === 'updated') {
          const updatedFeature = result.feature;
          const project = getProject();
          const token = getToken();
          this.store.showLoading();

          this.template.feature.geometry_type = updatedFeature.geometry ?
          updatedFeature.geometry.type : null;
          const attributes = this.template.feature.attributes as Attribute[];
          const attrKeys = Object.keys(updatedFeature.attributes);
          let attrNames = [];

          attributes.forEach(attribute => {
            attrNames.push(attribute.name);
            if (!this.valueExistInArarray(attribute.name, attrKeys)) {
              const tpe = attribute.type;
              let val = null;
              if (tpe === 'file') { val = []; }
              updatedFeature.attributes[attribute.name] = val;
            }

          });

          attrKeys.forEach( val => {
            if (!this.valueExistInArarray(val, attrNames)) {
              delete updatedFeature.attributes[val];
            }
          });
          this.featureService.updateFeature(updatedFeature, this.template, project, token)
          .subscribe(
            res => {
              if  (featureIndex >= 0) {
                this.instances[featureIndex] = res;
                this.dataSource.data = this.createFeatureData();
                this.featuretable.renderRows();
              }
              this.store.hideLoading();
            },
            err => {
              this.showErrorMessage(err);
              this.store.hideLoading();
            });
        }
      }
    });
  }

  isDataIsFile(column, data): boolean {
    const fieldsAndType = this.fields_and_name_type;
    if (fieldsAndType[column] === ATTRIBUTESETARRAY) {
      return false;
    }
    if (fieldsAndType[column] === COORDINATES) {
      return false;
    }
    const temp = this.checkFiles(data);
    return this.checkIfIsUrl(temp);
  }

  checkIfIsUrl(data) {
    const pat = /^https?:\/\//i;
    let values = [];

    if (data === undefined || data === null) {
      return false;
    }
    let str;
    if ((data instanceof Object) && (data instanceof Array) && data.length > 0) {
      str = data[0];
    } else {
      str = data;
    }
    if (isNaN(str)) {
      if (typeof(str) === 'string') {
        values = str.split('/');
      }
    }
    const currentDomain = getCurrentDomain();
    return pat.test(str) && values.includes(currentDomain) && values.includes('v1') && values.includes('files');

  }


  getHeaderInSplitMode(h): string {
    let parts = h.split('.');
    if (parts && parts.length > 0) {
      let tempHeader = '';
      for(const part of parts) {
        tempHeader = tempHeader === '' ? part : `${tempHeader} <br /> ${part}`;
      }
      return tempHeader;
    }
    return h;
  }

  // send the request for fetching features from the server.
  fetchFeatures() {
    this.linksArray = [];
    if (this.nbrPage === 0) {
      this.nbrPage = 100;
    }

    if (this.template.id === undefined || this.template.id === null || this.template.id === '') {
      return;
    }
  }

  saveTemplateFieldsNameAndType(template) {
    const feature = template.feature;
    const attributes = feature.attributes;
    let parent = '';
    let fieldsAndType = {};

    for (const attribute of attributes) {
      if (attribute._class === 'attributeset' ) {
        const temparent = (parent)
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        fieldsAndType = Object.assign(fieldsAndType, this.saveAttributesetNamesAndType(attribute, temparent));
      } else if (attribute._class === 'arrayattributeset') {
        const name = (parent)
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        fieldsAndType[name] = 'arrayattributeset';
      } else {
        const attname = (parent)
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        fieldsAndType[attname] = attribute['type'];
      }
    }
    this.schemaHeader = Object.keys(fieldsAndType);
    this.fields_and_name_type = fieldsAndType;
    saveTemplateFieldsNameAndTypes(fieldsAndType);
  }

  saveAttributesetNamesAndType(attributeset, parent) {
    let obj = {};
    let attsetName = attributeset['name'];
    if (attsetName === null || attsetName === undefined || attsetName === '') {
      attsetName = '';
    }
    const parentname = (parent !== null && parent !== undefined && parent !== '')
            ? `${parent}.${attsetName}`
            : attsetName;

    for (const attribute of attributeset.attributes) {

      if (attribute._class === 'attribute') {
        const attname = `${parent}.${attribute.name}`;
        obj[attname] = attribute['type'];
      } else if (attribute._class === 'arrayattributeset') {
        const name = `${parent}.${attribute.name}`;
        obj[name] = 'arrayattributeset';
      } else {
        obj = Object.assign(obj, this.saveAttributesetNamesAndType(attribute, parentname));
      }
    }
    return obj;
  }

  /**
   * getting the values of nested attributes.
   */
  parseAttributesObject(attributes, path) {
    const propertyNames = Object.getOwnPropertyNames(attributes);
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      const name = propertyNames[i];
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (!this.checkArrayAttributeSet(currentPath)) {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
            if (!this.checkCoordinateField(currentPath)) {
              this.parseArrayObject(attribute, currentPath);
            }
          } else {
            this.parseAttributesObject(attribute, currentPath);
          }
        }
      }
    }
  }

  parseArrayObject(attribute, path) {
    const size = attribute.length;
    const tempSize = this.findArrayMaxSize(path, size);
    if (this.findObjectInStoredArraySizes(path) <= 0) {
      const element =  {
        name: path,
        size: tempSize
      };
      this.arrayHeadersAndSize.push(element);
    }

    this.replaceHeaderInHeaders(path, tempSize);
    for (let i = 0; i < size; i++) {
      const data = attribute[i];
      const arrPath = path + '[' + i + ']';
      if (data instanceof Object) {
        if (data instanceof Array) {
          this.parseArrayObject(data, arrPath);
        } else {
          this.parseAttributesObject(data, arrPath);
        }
      } else {

      }
    }
  }

  // If the template contains file types find the longest array in order to fill others with empty space.
  findArrayMaxSize(path, size) {
    let tempSize = size;
    for (const instance of this.instances) {
      const attributes = instance.attributes;
      const findSize = this.findArrayMaxSizeInObject(attributes, path, '', tempSize);
      if (findSize > 0) {
        tempSize = findSize;
      }
    }
    return tempSize;
  }

  checkCoordinateField(path) {
    const fieldAndTypes = this.fields_and_name_type;
    const type = fieldAndTypes[path];
    return type === 'coordinates';


  }

  checkArrayAttributeSet(path) {
    const fieldAndTypes = this.fields_and_name_type;
    const type = fieldAndTypes[path];
    return type === 'arrayattributeset';


  }

  findArrayMaxSizeInObject(attribute, searchPath, path, size) {
    const propertyNames = Object.getOwnPropertyNames(attribute);

    for (const name of propertyNames) {
      const tempAttribute = attribute[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;

      if (tempAttribute instanceof Object) {
        if (tempAttribute instanceof Array) {
          if (searchPath === currentPath) {
            if (tempAttribute.length > size) {
              return tempAttribute.length;
            }
          }
        } else {
          this.findArrayMaxSizeInObject(tempAttribute, searchPath, currentPath, size);
        }
      }
    }
    return 0;
  }

  // adding [] to array's type in the header of the table.
  replaceHeaderInHeaders(element, size) {
    if (size === 0) {
      return;
    }

    for (let i = 0; i < this.attHeaders.length; i++) {
      if (this.attHeaders[i] === element) {
        let elements = '';
        for (let j = 0; j < size; j++) {
          elements = elements === '' ? '' : elements + ' ';
          elements = elements + element + '[' + j + ']';
        }
        const splitElements = elements.split('');
        for (let k = 0; k < splitElements.length; k++) {
          if (k === 0) {

            this.attHeaders.splice(i + k, 1, splitElements[k]);
          } else {
            this.attHeaders.splice(i + k, 0, splitElements[k]);
          }
        }
        return;
      }
    }
  }

  // buiding values of nested objects and arrays
  buildObjectData(attributes, dataArray, path, oldValues = null) {
    let propertyNames = [];
    this.initHeaders = this.splitHeaderValues(this.initHeaders);
    if (oldValues === null) {
      propertyNames = this.initHeaders;
    } else {
      propertyNames = Object.getOwnPropertyNames(attributes);
    }
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      let name = propertyNames[i];
      const value = name.split('.');
      if (value.length > 1) {
        name = value[0];
        value.shift();
      }
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (attribute === null || attribute === undefined) {
        if (this.findObjectInStoredArraySizes(currentPath) > 0) {
          this.buildArrayData(attribute, dataArray, currentPath);
        } else {
          this.featureAttrib.push(name);
          dataArray.push('');
        }
      } else {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
            if (this.checkArrayAttributeSet(currentPath)) {
              this.allArrayAttributeSet.push(attribute);

              const elt = currentPath + '_' + this.allArrayAttributeSetElemment.length;
              this.allArrayAttributeSetElemment.push(elt);
              dataArray.push(elt);
            } else if (this.checkCoordinateField(currentPath)) {
              const coordinate = {
                x: attribute[0],
                y: attribute[1],
                z: attribute[2],
                Accuracy: attribute[3]
              };
              this.allCoordinatesFields.push(coordinate);
              const elt = currentPath + '_' + this.allCoordinatesFieldsElemment.length;
              this.allCoordinatesFieldsElemment.push(elt);
              dataArray.push(elt);
            } else {
              this.buildArrayData(attribute, dataArray, currentPath);
            }
          } else {
            this.buildObjectData(attribute, dataArray, currentPath, value);
          }
        } else if (attribute instanceof Array) {
          if (this.checkArrayAttributeSet(currentPath)) {
            this.allArrayAttributeSet.push(attribute);

            const elt = currentPath + '_' + this.allArrayAttributeSetElemment.length;
            this.allArrayAttributeSetElemment.push(elt);
            dataArray.push(elt);
          }
        } else {
          this.featureAttrib.push(name);
          if (this.checkIfValueInArray(name)) {
            let data = this.checkFiles(attributes[name]);
            if (this.checkIfIsUrl(data)) {
              this.linksArray.push(data);
            } else if (this.checkIfDate(data, name)) {
              data = data.split('T')[0];
            }
            dataArray.push(data);
          }
        }
      }
    }
  }

    /*
    * check if value is a media file and return the complete URL.
    * @param: the value to check
    * @return: the URL or the string
    */
  checkFiles(value) {
    if (value instanceof Array && value.length > 0) {
      const firstVal = value[0];
      return this.checkFile(firstVal);
    } else {
      return value;
    }
  }

  checkFile(value) {
    const convValue = String(value);
    const splitValues = convValue.split('.');
    const types = ['jpg', 'png', 'jpeg', 'pdf', 'gif', 'mp3', 'wav', 'raw', 'ogg', 'mp4', '3gp', 'avi', 'vob', 'flv'];
    let type = splitValues[splitValues.length - 1];
    type = type.toLowerCase();
    if (this.contains(type, types)) {
      return url(value);
    } else {
      return value;
    }
  }

  getIcon(item): string{
    let uri = '';
    if(item !== null && item !== undefined) {
      const values = item.split('.');

      const ext = values[values.length - 1];

      if(ext === 'jpeg' || ext === 'png' || ext === 'jpg' || ext === 'gif' || ext === 'pdf'){
        uri = '/assets/images/photo_display.png';
      } else if(ext === 'mp4' || ext === '3gp' || ext === 'avi' || ext === 'vob' || ext === 'flv'){
        uri = '/assets/images/video_display.png'
      } else if(ext === 'mp3' || ext === 'wav' || ext === 'raw' || ext === 'ogg'){
        uri = '/assets/images/audio_play.png'
      }

      return uri;
    }
  }

  getFilesUrl(value) {
    const filesUrls = [];
    if (value instanceof Array && value.length > 0) {
      value.forEach(element => {
        filesUrls.push(this.checkFiles(element));
      });
    } else {
      return value;
    }
    return filesUrls;
  }

  // check if the value is date format or not.
  checkIfDate(value, name) {
    if (value !== null && value !== undefined && value !== '' && typeof value === 'string') {
      const colomValues = value.split(':');
      const slashValues = value.split('-');
      if (colomValues.length > 2 && slashValues.length > 2) {
        return true;
      }
    }
    return false;
  }

  // split array data build in string in to elements of an array.
  splitHeaderValues(headers) {
    const values = [];
    if (headers.length === 0) {
      return;
    }

    for (const header of headers) {
      const value = header.split('.');
      if (!this.contains(value[0], values)) {
        values.push(value[0]);
      }
    }
    return values;
  }

  checkIfValueInArray(value) {
    if (this.attHeaders.length > 0) {
      for (const attHeader of this.attHeaders) {
        if (this.getNameOfHeader(attHeader) === value) {
          return true;
        }
      }
      return false;
    }
  }

  getNameOfHeader(value) {
    const values = value.split('.');
    if (values.length > 0) {
      return values[values.length - 1];
    }
    return value;
  }

  contains(value, arr) {
    if (arr.length > 0) {
      for (const item of arr) {
        if (item === value) {
          return true;
        }
      }
      return false;
    }
  }

  // building the data of arrays(media files values).
  buildArrayData(attribute, dataArray, path = '') {
    const highestLength = this.findObjectInStoredArraySizes(path);
    let size = 0;
    if (attribute !== null && attribute !== undefined) {
      size = attribute.length;
    }

    if (size === 0 && highestLength === 0) {
      dataArray.push('');
    }

    for (let i = 0; i < size; i++) {
      let data = attribute[i];

      if (data instanceof Object) {
        if (data instanceof Array) {
          this.buildArrayData(data, dataArray);
        } else {
          this.buildObjectData(data, dataArray, '');
        }
      } else {
        data = this.checkFiles(data);
        if (this.checkIfIsUrl(data)) {
          this.linksArray.push(data);
          dataArray.push(data);
        } else {
          dataArray.push(data);
        }
      }
    }
    if (highestLength > size) {
      const remainSize = highestLength - size;
      for (let j = 0; j < remainSize; j++) {
        dataArray.push('');
      }
    }
  }

  findObjectInStoredArraySizes(path) {
    for (const header of this.arrayHeadersAndSize) {
      if (header.name === path) {
        return header.size;
      }
    }
    return 0;
  }

  isEmpty(obj) {
    if (obj === null || obj === undefined) {
      return true;
    }

    for (const prop of Object.keys(obj)) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  showAllColumns() {
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  selectAllFeatures(event) {
    this.masterToggle();
    this.store.features = null;
    if (event.checked) {
      this.store.features = this.instances;
    }
  }

  showExportDialog() {
    const dialogRef = this.dialog.open(ExportDataCriteriaDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '70vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: {
        template: getTemplate(),
        headers: this.tableHeaders
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null && result !== undefined && result.event === 'search') {
        this.submitSeletedItems2(result.advanced_request);
      }
    });
  }

  resetSelectedFeature () {
    this.store.copiedFeatures = [];
  }

  showViewDialog() {
    const dialogRef = this.dialog.open(ViewDataCriteriaDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '70vh',
      width: '50vw',
      height: '70vh',
      disableClose: true,
      data: {
        template: getTemplate(),
        headers: this.tableHeaders
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null && result !== undefined && result.event === 'search') {
        this.submitSeletedItems2(result.advanced_request);
      }
    });
  }

  showImportPage() {
    persistLastMenu('import');
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.store.showImportDataInTemplate();
  }

  /**
   * manage the choices made by user in advanced searching.
   */
  submitSeletedItems2(selectedColumns): void {
    const datesValues = selectedColumns.datesValues;
    this.sortedObject = selectedColumns.sortingObject;
    let columnsSelected = selectedColumns.ColumsValues;
    const aggregate = selectedColumns.aggregateBody;
    const output = selectedColumns.outputType;
    this.advancedSearchCriteria = null;
    this.aggregation = [];
    this.columnsValues = [];

    if (output === 'view_web_app') {
      for(let i = 0; i < columnsSelected.length; i++) {
        const ind = this.tableHeaders.indexOf(columnsSelected[i]);
        this.columnsValues.push(this.attHeaders[ind]);
      }
    } else {
      this.columnsValues = columnsSelected;
    }

    const y = this.columnsValues.indexOf('Coordinates');

    if (y !== -1) {
      this.columnsValues.splice(y, 1);
      this.columnsValues.push('Geometry Type');
    }

    this.fromDate = datesValues.fromDate;
    this.toDate = datesValues.toDate;

    this.showAllColumns();
    if (aggregate !== null && aggregate.length > 0) {
      this.aggregation = aggregate;
      if (output === 'view_web_app') {
        this.aggregationSearch(aggregate);
      } else {
        let placeholders = [];
        //Calculates placeholders into correct format:
        if (selectedColumns.reportObject.holders.length > 0) {
          const placeholders = selectedColumns.reportObject.holders.map(obj => ({
            [obj.name]: obj.value
          }));
        }

        let report_template_id = null;

        if(selectedColumns.selectedFormat.show_report_templates)
          report_template_id = selectedColumns.reportObject.selectedReport.id

        let requestBody = {
          configuration: {
            format: selectedColumns.outputType,
            split_type: "single_file", // Other options not yet available
            crs: getChoosenCRSType(),
          },
          report_template_id: report_template_id,
          place_holders: placeholders,
          queries: [],
        }

        this.RequestReport(output, requestBody);
      }
    } else {
      this.fetchFeatures();
    }
  }

  valueExistObject(obj, value) {
    if (this.isEmpty(obj)) {
      return false;
    }

    const keys = Object.keys(obj);
    if (keys.length > 0) {
      for (const val of keys) {
        if (value === val) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * This method send creation report request or get the
   * aggregation search to update the data
   * @param reportType the type of the report
   * @param requestBody report body for the request.
   */
  RequestReport(reportType, requestBody) {
    this.reportType = reportType;
    if (this.doingReport === false) {
      this.doingReport = true;
      if (this.reportType === 'Select Type') {
        this.showErrorMessage('Please select the report type');
        return;
      }
      this.verifyReportObjects(this.sortedObject);
      this.operators = [];
      this.advancedSearchCriteria.template_id = this.template.id;
      if (this.fromDate !== undefined && this.fromDate !== null) {
        this.advancedSearchCriteria['created_at.value'] = { $gte : this.fromDate + 'T00:00:00Z'};
      }
      if (this.toDate !== undefined && this.toDate !== null) {
        this.advancedSearchCriteria['created_at.value']  = { $lte : this.toDate + 'T00:00:00Z'};
      }
      if (this.aggregation.length > 0) {
        this.operators.push(this.aggregation[0]);
      } else {
        this.operators.push({ '$match': this.advancedSearchCriteria });
      }

      if (!this.isEmpty(this.sortedObject)) {
        this.operators.push({ '$sort': this.sortedObject });
      }

      if (!this.isEmpty(this.projectionObject)) {
        const oderObject = this.rebuildFields(this.projectionObject);

        const orderProject2 = this.createProject2(this.projectionObject);

        this.operators.push({ '$project': oderObject});

        this.operators.push({ '$project': orderProject2});
      }

      requestBody.queries = [{
        collection: 'features',
        stages: this.operators
      }]
      this.generateNewReport(requestBody)
    }
  }

  generateNewReport(requestBody) {
    this.store.showLoading();
    this.reportService.createNewReport(getToken(), requestBody).subscribe(
      res => {
        this.doingReport = false;
        this.store.hideLoading();
        this.showSuccessMessage('The request to create the report has been sent.');
      },
      err => {
        if(err.error.error)
          this.showErrorMessage(err.error.error.message);
        else if(typeof err.message === 'string')
          this.showErrorMessage(err.message);
        else
          this.showErrorMessage("The request you sent was not correct, please try again.");
        this.doingReport = false;
        this.store.hideLoading();
      }
    )
  }

  rebuildFields(projectObject) {
    const newObject = {};
    if (!this.isEmpty(projectObject)) {
      newObject['_id'] = 0;
      const keys = Object.keys(projectObject);
      for (let i = 0; i < keys.length; i++){
        if (keys[i] === 'geometry') {
          newObject['geometry'] = '$' + keys[i];
        } else {
          const key = this.splitKeyValue(keys[i]);
          const vals = key.split(".");
          if(vals.length > 1){
            newObject[vals[0] + "." + "_class"] = '$' + this.concatenateClass(keys[i]);
          }
          newObject[key] = '$' + keys[i];
        }
      }
    }

    return newObject;
  }

  concatenateClass(val): any{
    const vals = val.split(".");
    if(vals.length > 1){
      vals[vals.length - 1] = "_class";
    }

    return vals.join('.');
  }

  splitKeyValue(value): any{
    const elements = value.split('.');
    let key = "";

    if(elements[elements.length - 2] == 'attributes'){
      key = elements[elements.length - 1];
    } else {
      key = elements[elements.length - 2] + "." + elements[elements.length - 1];

    }

    return key;
  }

  createProject2(projectObject) {
    const obj = {};
    if (!this.isEmpty(projectObject)) {
      const keys = Object.keys(projectObject);
      for (let i = 0; i < keys.length; i++){
        if (keys[i] === 'geometry') {
          obj['geometry'] = '$' + keys[i];
        } else {
          const val = this.splitKeyValue(keys[i]);
          const vals = val.split('.');
          if(vals.length > 1){
            obj['attributes.' + vals[0] + '._class'] = '$' + vals[0] + '._class';
          }
          const key = 'attributes.' + val;
          obj[key] = '$' + val;
        }
      }
    }

    return obj;
  }

  verifyReportObjects(sortedObject) {
    let sortingValue;
    let sortingValue2;
    let sortingType = 1;
    let sortingType2 = 1;

    if (this.advancedSearchCriteria !== null && Object.keys(this.advancedSearchCriteria).length > 0) {
      const keys = Object.keys(this.advancedSearchCriteria);
      if (keys[0].split('.').length > 1) {
        return;
      }

      if (keys.length === 1 && keys[0] === 'template_id') {
        return;
      }

      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          const value = this.advancedSearchCriteria[keys[i]];
          if (typeof value === 'object') {
            const ObjKey = Object.keys(value);
            let valKey = ObjKey[0];
            valKey = '$' + valKey;

            value[valKey] = value[ObjKey[0]];
            delete value[ObjKey[0]];
          }

          key = 'attributes.' + key + '.value';
          this.advancedSearchCriteria[key] = value;
          delete this.advancedSearchCriteria[keys[i]];
        }
      }
      this.advancedSearchCriteria.template_id = this.template.id;
    } else {
      this.advancedSearchCriteria = {};
      this.advancedSearchCriteria.template_id = this.template.id;
    }

    if (sortedObject !== null && Object.keys(sortedObject).length > 0) {
      if (sortedObject.column !== '') {
        sortingValue = 'attributes.' + sortedObject.column + '.value';
        if (sortedObject.sortingType === 'descending') {
          sortingType = -1;
        }

        this.sortedObject[sortingValue] = sortingType;
        delete this.sortedObject.column;
        delete this.sortedObject.sortingType;
      } else {
        delete this.sortedObject.column;
      }

      if (sortedObject.column2 !== '') {
        sortingValue2 = 'attributes.' + sortedObject.column2 + '.value';
        if (sortedObject.sortingType2 === 'descending') {
          sortingType2 = -1;
        }

        this.sortedObject[sortingValue2] = sortingType2;
        delete this.sortedObject.column2;
        delete this.sortedObject.sortingType2;
      } else {
        delete this.sortedObject.column2;
      }
    }

    if (this.columnsValues === null || this.columnsValues.length === 0) {
      this.columnsValues = this.attHeaders;
    }

    if (this.columnsValues !== null) {
      if (this.columnsValues.length > 0) {
        this.projectionObject = {};
        const sameValue = [];
        for(let j = 0; j < this.columnsValues.length; j++) {
          let value = this.columnsValues[j];
          value = value.trim();
          if (value.split('[').length > 1) {
            value = value.split('[')[0];
          }
          let value1 = null;
          if (value.split('.').length > 1) {
            value1 = value.split('.')[0];
            sameValue.push(value1);
          } else {
            value1 = value;
          }
          let attr = 'attributes.' + value;
          if (attr === 'attributes.Geometry Type') {
            attr = 'geometry';
            value = 'geometry';
          }

          if (!this.valueExistObject(this.projectionObject, attr)) {
            const values = value.split('.');
            this.projectionObject[attr] = '$' + values[values.length - 1];
          }
        }
      } else {
        this.projectionObject = {};
      }
    }
  }

   /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  /**
   * This method send the aggreate search to the server and
   * call the updateDataFromServer when success
   */
  aggregationSearch(aggregate) {
    if (aggregate.length <= 0) {
      return;
    }

    const token = getToken();
    let crs = getChoosenCRSType();
    if (!crs) {
      crs = 'Geodetic';
    }

    const body = JSON.stringify({
      format: ['json'],
      crs,
      queries: [{
        collection: 'features',
        stages: aggregate
      }]
    });
    this.store.showLoading();
    this.featureService.aggregateSearch(token, body).subscribe(
      res => {
        removeSearchValue();
        removeCurrentFeaturesUri();
        this.updateDataFormServer(res);

        this.store.hideLoading();
      },
      err => {
        this.showErrorMessage(err);
        this.store.hideLoading();
      }
    );
  }
}
