import { Component, OnInit, ViewChild, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { TemplateService } from 'src/app/services/template.service';
import { StoreService } from 'src/app/services/store.service';
import { getToken, getProject, setFormUpdating } from 'src/app/share/utils';
import { MatRadioChange, MatDialog } from '@angular/material';
import { Project } from 'src/app/share/projects';
import { User } from 'src/app/share/user';

import { Token } from 'src/app/share/token';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ImportErrorDlgComponent } from '../import-error-dlg/import-error-dlg.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';

const ELEMENT_DATA: any[] = [];

const IMPORT_DIALOG_TITLE = 'Choose Shape file';

@Component({
  selector: 'app-import-shape-file',
  templateUrl: './import-shape-file.component.html',
  styleUrls: ['./import-shape-file.component.scss']
})
export class ImportShapeFileComponent implements OnInit {


  @Output('readProjectTemplate')
  readProjectTemplateEmitter = new EventEmitter<any>();

  importDialogTitle: string;
  project: Project;
  template: Template;
  newTemplate: any;
  @Input() user: User;
  @Input() mode = 'Create';
  @Input() typeOfFile: string;

  loading = false;
  loadingText = '';

  model: string;
  encoding = 'UTF8';
  type: string;
  fileInBase64: string;

  fileName: string;

  fileToUpload: File;
  importShape = false;
  importInExistingTemplate = false;
  isValidFile = true;
  importFileForm: FormGroup;
  validFile = false;
  importDataError: any;
  featureSize = 0;

  crsList = [
    {value: 'Geodetic', text: 'Geodetic'},
    {value: 'Lambert72', text: 'Lambert 72'},
    {value: 'Lambert2008', text: 'Lambert 2008'},
    {value: 'RDNAP', text: 'RD'},
    {value: 'UTM', text: 'UTM'},
    {value: 'BGS2005', text: 'BGS2005'}
  ];

  fileTypes = [
    {value: 'shape',  text: 'Shape'}
  ];

  fieldTypes = [
    {value: 'shape',  text: 'Shape'}
  ];

  fields = [];
  tempFields: any[] =  [];
  showFieldContainer = false;

  errMsg: string;
  submitingForm: boolean;
  formreseted: boolean;
  templates: any[];
  noFileIsSelected = false;


  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = 'Test of message';
  showMessage = false;

  @ViewChild('fileInput', { static: false}) fileInput: ElementRef;
  @ViewChild('fform', { static: false}) importFileFormDirective;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    private templateService: TemplateService,
    protected elementRef: ElementRef,
    private dialog: MatDialog) {
      this.isValidFile = false;
      this.importDialogTitle = 'Import Shape';
      this.model = 'shape';
  }


  ngOnInit() {
    if (this.store.importIKMLFile) {
      this.importShape = this.store.importIKMLFile;
    }

    this.project = getProject();
    this.createForm();
  }

  selectFile(): void {
    this.fileInput.nativeElement.click();
  }

  typeTemplateChange(event: MatRadioChange, data) {
    if (data === 'new_template') {
      this.importShape = false;
    } else {
      this.importShape = true;
    }
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    if (this.messageBox) {
      this.messageBox.showError();
    }
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      // this.messageBox.showSuccess();
    });
  }

  createForm() {
    if (this.mode === 'Create') {
      this.importFileForm = this.fb.group({
        shapeFile: ['', Validators.required],
        crs: [''],
        template: ['', Validators.required]
      });
    }
  }

  closeDialog(event) {
    event.preventDefault();
    if (this.showFieldContainer) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          message: ``,
          title: 'Cancel import?'
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {
          this.fileToUpload = null;
          this.store.updatingForm = false;
          this.showFieldContainer = false;
          this.fields = null;
          this.fileInBase64 = null;
          this.store.hideTemplate();
        }
      });
    } else {
      this.fileToUpload = null;
      this.showFieldContainer = false;
      this.store.updatingForm = false;
      this.fields = null;
      this.fileInBase64 = null;
      this.store.hideTemplate();
    }
    event.stopPropagation();
  }

  closePanel() {
    this.showMessage = false;
  }


  showErrorDlg(): void {
    const dialogRef = this.dialog.open(ImportErrorDlgComponent, {
      width: '500px',
      data: {
        error: this.importDataError
      }
    });
    dialogRef.afterClosed().subscribe(result  => {

    });
  }

  onSubmit() {
    if (!this.fileToUpload && !this.fileInBase64) {
      this.noFileIsSelected = true;
      this.showErrorMessage('No file is selected');
      return;
    } 
    if (this.fileInBase64.length <= 0 ) {
      this.showErrorMessage(`The 'base64 content' may not be empty, (either the file is not selected or the file is too big and the conversion failed)`);
      return;
    }
    this.showMessage = false;
    const importData = this.importFileForm.value;

    let fileType = 'shape';

    let p = getProject();


    const value = {
      project_id: this.project.id,
      base64_encoded_content: this.fileInBase64,
      template: importData['template'],
      crs: importData['crs'],
      model : this.model,
      encoding: 'UTF8',
      type: this.type
    };
    this.store.showLoading();
    this.loadingText = 'Sending importation data';
    const token = getToken();
    this.templateService.importShapeInTemplate(value, token, p.id).subscribe(
      res => {
        debugger;
        this.showSuccessMessage(`Importation succeed for form.` );
        setFormUpdating(false);
        this.store.updatingForm = false;
        this.fileToUpload = null;
        this.showFieldContainer = false;
        this.fileInBase64 = null;
        this.store.hideTemplate();
        this.store.createTemplate(res);
        this.store.readTemplate(res);
        this.getTemplates(p);
        this.store.hideLoading();
      },
      error => {
        this.loading = false;
        this.store.hideLoading();
        setFormUpdating(false);
        this.store.updatingForm = false;
        // p = getProject();
        // this.getTemplates(p);
        if (error instanceof Array) {
          this.importDataError = error;
        } else {
          this.importDataError = [error]
        }
        let msg = error ? `Error: ${error}` : `Importation failed, please retry later`;
        if (error instanceof Array) {
          // const isOrAre = error.length > 1 ? 'are' : 'is';
          const record = error.length > 1 ? 'records' : 'record';
          msg = `${error.length} ${record} could not be imported.` ;
        }
        this.showErrorMessage(msg);
      }
    );
  }



  selectionChange(event: StepperSelectionEvent) {
    const val = this.importFileForm.value;
    this.model = val['model'];
  }


  handleData(data: any) {
    setTimeout(() => {
      // data.isCoordinate = !data.isCoordinate;
    });
  }

  getTemplates(p): void {
    const token: Token = JSON.parse(localStorage.getItem('token'));

    this.store.showLoading();
    this.templateService.getTemplates(token, p.id).subscribe(
      res => {
        this.store.readTemplates(res);
        this.store.hideLoading();
      },
      errmess => {
        this.store.hideLoading();
        this.showErrorMessage(errmess);
    });
  }

  onlyDigits(str) {
    const pattern = /^\d+$/;
    return pattern.test(str);  // returns a boolean
  }

  setFileData(event): void {
    event.preventDefault();

    this.showFieldContainer = false;
    debugger;
    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];

      this.isValidFile = true;
      const filename = tempFile.name;
      this.fileName = tempFile.name;
      const filenameParts = filename ? filename.split('.') : [];
      if (filenameParts && filenameParts.length > 1) {
        this.type = filenameParts[filenameParts.length -1];
        this.importFileForm.patchValue({
          template: filenameParts[0]
        });
      }

      // setFormUpdating(true);
      this.store.updatingForm = true;
      this.fileToUpload = event.target.files[0];
      this.convertFileToBase64(this.fileToUpload);
      this.showFieldContainer = true;
    } else {
      this.isValidFile = false;
    }
    event.stopPropagation();
  }

  convertFileToBase64(file) {
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryString = reader.result;
            this.fileInBase64 = btoa(binaryString as string);
        };
        reader.readAsBinaryString(file);
    }
  }

  convertFileToString(): Observable<any> {
    return null;
  }

}
