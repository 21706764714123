import { Injectable, EventEmitter } from '@angular/core';
import { User } from '../share/user';
import { Token } from '../share/token';
import { Project, ProjectToCopy, ProjectToDuplicate } from '../share/projects';
import { Observable, of, pipe } from 'rxjs';
import { delay, map, catchError  } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { getPrivateApiAURL() } from '../share/getPrivateApiAURL()';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { ProjectsResult, ProjectResult, ProjectsOfCollaborators, SearchResult } from '../share/result';
import { getToken, getPrivateApiAURL } from '../share/utils';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  projects: Project[] = [];
  public projectAdded: EventEmitter<Project>;
  public projectUpdated: EventEmitter<Project>;
  public projectDeleted: EventEmitter<Project>;

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
    this.projectAdded = new EventEmitter();
    this.projectUpdated = new EventEmitter();
    this.projectDeleted = new EventEmitter();

    const token = getToken();
    /*
    this.getProjects(token).subscribe(
      res => {
        this.projects = res;
      },
      errmess => {
        // console.log(errmess);
    });
    */
  }

  deleteProject(project, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': proToken.value
      }),
      params: new HttpParams({

      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Admin-Auth-Token': proToken.value
        }),

        params: new HttpParams({

        })
      };
    }
    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.delete<ProjectResult>(getPrivateApiAURL() + 'projects/' + project.id, httpOptions)
    .pipe(map(
      res => {
      return res.project;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateProject(project, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        })
      };
    }

    return this.http.put<ProjectResult>(getPrivateApiAURL() + 'projects/' + project.id,
              project, httpOptions).pipe(map(
      res => {
      return res.project;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createProject(project, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {};
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Auth-Token': proToken.value
        })
      };
    }

    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.post<ProjectResult>(getPrivateApiAURL() + 'projects', project, httpOptions)
    .pipe(map(
      res => {
      return res.project;
    }))
    .pipe(
      catchError(this.processHttpMsgService.handleError)
      );

    /*
    .pipe(
      map(
        result => {
          // this.add(result.project);
          return result.project;
        })
    )
    */
  }

  searchProject(strValue: string, token: Token): Observable<SearchResult> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'X-Auth-Token': token.key
      })
    };

    return this.http.post<SearchResult>(getPrivateApiAURL() + 'globalsearch/features', strValue, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  pasteFeatures(destinationProjectId:string, destinationFormId: string, features,  token: Token): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const request = {
      destination_project :destinationProjectId,
      destination_form:destinationFormId,
      features: features
    };
    return this.http.post<SearchResult>(getPrivateApiAURL() + 'global_copy/features', request, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getProject(project, proToken: ProjectToken, isMyCumulusAdministrator=false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      }),
      params: new HttpParams({

      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        }),

        params: new HttpParams({

        })
      };
    }

    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.post<Project>(getPrivateApiAURL() + 'projects' + project.id, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getProjects(proToken: ProjectToken, proClass, visibility, archived, isMyCumulusAdministrator = false): Observable<Project[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      }),
      params: new HttpParams()
        .set('proClass', proClass)
        .set('visibility', visibility)
        .set('archived', archived)
        .set('perPage', '500')
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        }),
        params: new HttpParams()
          .set('proClass', proClass)
          .set('visibility', visibility)
          .set('archived', archived)
          .set('perPage', '500')
      };
    }

    return this.http.get<ProjectsResult>(getPrivateApiAURL() + 'projects', httpOptions)
    .pipe(map(result => {
      return result.projects.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getCollaboratorProjects(token, proClass, visibility, archived): Observable<Project[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      }),
      params: new HttpParams()
        .set('proClass', proClass)
        .set('visibility', visibility)
        .set('archived', archived)
        .set('perPage', '500')
    };

    return this.http.get<ProjectsOfCollaborators>(getPrivateApiAURL() + 'projects', httpOptions)
    .pipe(map(result => {
      if(result['projects']) {
        return result['projects'].instances;
      } else {
        return result.project_members.instances;
      }

    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getProjects1(): Observable<Project[]> {
    const t = {};
    const u = {};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      })
    };
    return this.http.get<Project[]>(getPrivateApiAURL() + 'projects', httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  duplicateProject(project: ProjectToDuplicate, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        })
      };
    }
    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.post<ProjectResult>(getPrivateApiAURL() + 'project/duplicate', project, httpOptions)
    .pipe(map(
      res => {
      return res.project;
    }))
    .pipe(
      catchError(this.processHttpMsgService.handleError)
      );
  }

  copyProjectFromTemplate(project: ProjectToCopy, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        })
      };
    }
    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.post<ProjectResult>(getPrivateApiAURL() + 'project/template/copy', project, httpOptions)
    .pipe(map(
      res => {
      return res.project;
    }))
    .pipe(
      catchError(this.processHttpMsgService.handleError)
      );
  }

  public list(): Project[] {
    return this.projects;
  }

  private add(project: Project) {
    this.projects.push(project);
    this.projectAdded.emit(project);
  }

  public update(project: Project) {
    const projectIndex = this.projects.indexOf(project);
    // Update element project
  }

  public delete(project: Project) {
    // Remove element from the list of project
  }
}
export const PROJECTS: Project[] = [
];

export interface ProjectToken {
  key: string;
  value: string;
}
