import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatTable, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { getToken, getCRSSetting } from 'src/app/share/utils';
import { CrsService } from 'src/app/services/crs.service';
import { StoreService } from 'src/app/services/store.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'app-geom-type-coordinates-dialog',
  templateUrl: './geom-type-coordinates-dialog.component.html',
  styleUrls: ['./geom-type-coordinates-dialog.component.scss']
})
export class GeomTypeCoordinatesDialogComponent implements OnInit, AfterViewInit {
  coordinates: any[];
  geodetic: boolean;
  isGPSCoord: boolean;
  geomType: string;
  geometryData: any[];
  displayedColumns: string[] = [];
  feature: any;
  coordModified: boolean = false;
  
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  @ViewChild(MatTable, {static: true}) coordinatestable: MatTable<any>;

  constructor(
    private conversionCoordinateService: CrsService,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<GeomTypeCoordinatesDialogComponent>) {
    if (data !== undefined && data !== null) {
      this.feature = data.feature;
      this.geometryData = data.coordinates;
      this.isGPSCoord = data.gpscoord;
      this.geomType = data.geometry_type;
    }
  }

  ngOnInit() {
    this.convertCoordinates(this.geometryData);
  }

  ngAfterViewInit() {
    
  }

  updateCoordinates() {
    if (this.coordModified)
      console.log(this.coordinates)
  }

  convertCoordinates(geodCoordinates) {
    this.coordinates = [];
    let crs = getCRSSetting();
    if (this.geomType !== null) {
      if (this.geomType === 'Point') {
        let accuracy = 0.0;
        if (geodCoordinates.length >= 4) {
          accuracy = geodCoordinates[3];
        }
        if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0  && geodCoordinates[2] === 0)) {
            const el = {
              latitude : geodCoordinates[0],
              longitude : geodCoordinates[1],
              altitude : geodCoordinates[2],
              accuracy: accuracy !== 0.0 ? accuracy : '?'
            };
            this.coordinates.push(el);
        }
      } else if (this.geomType === 'LineString' || this.geomType === 'Linestring') {
        geodCoordinates.forEach(geodCoordinate => {
          let accuracy = 0.0;
          if (geodCoordinate.length >= 4) {
            accuracy = geodCoordinate[3];
          }
          if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0 
            && geodCoordinates[2]  === 0)) {
              const item = {
                latitude : geodCoordinate[0],
                longitude : geodCoordinate[1],
                altitude : geodCoordinate[2],
                accuracy: accuracy !== 0.0 ? accuracy : '?'
              };
              this.coordinates.push(item);
          }
        });
      } else if (this.geomType === 'Polygon') {
        geodCoordinates.forEach(element => {
          element.forEach(geodCoordinate => {
            let accuracy = 0.0;
            if (geodCoordinate.length >= 4) {
              accuracy = geodCoordinate[3];
            }
            if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0 
              && geodCoordinates[2]  === 0)) {
                const item = {
                  latitude : geodCoordinate[0],
                  longitude : geodCoordinate[1],
                  altitude : geodCoordinate[2],
                  accuracy: accuracy !== 0.0 ? accuracy : '?'
                };
                this.coordinates.push(item);
            }
          });
        });
      }
    } else {
      let accuracy = 0.0;
      if (geodCoordinates.length >= 4) {
        accuracy = geodCoordinates[3];
      }
      if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0  && geodCoordinates[2]  === 0)) {
          const el = {
            latitude : geodCoordinates[0],
            longitude : geodCoordinates[1],
            altitude : geodCoordinates[2],
            accuracy
          };
          this.coordinates.push(el);
      }
    }

    if (crs === null || crs === undefined || crs === '' || crs === 'Geodetic') {
      crs = 'Geodetic';
      this.geodetic = true;
      this.createHeaderArray(this.geodetic);
      this.createDataArray();
    } else {
      const token = getToken();
      this.store.showLoading();
      this.conversionCoordinateService.convertCRS(token, crs, this.coordinates).subscribe(
        res => {
          this.geodetic = false;
          this.createHeaderArray(this.geodetic);
          this.coordinates = res;
          this.createDataArray();
          this.store.hideLoading();
        },
        err => {
          console.log(err);
          this.store.hideLoading();
        }
      );
    }
  }

  updateElementValue(event, element, value) {
    console.log(element);
    this.coordModified = true;
    console.log(value);
  }

  createHeaderArray(geodetic) {
    this.displayedColumns = []; 
    if (geodetic) {
      this.displayedColumns.push('Latitude');
      this.displayedColumns.push('Longitude');
      this.displayedColumns.push('Elevation');
      this.displayedColumns.push('Accuracy');
    } else {
      this.displayedColumns.push('X');
      this.displayedColumns.push('Y');
      this.displayedColumns.push('Z');
      this.displayedColumns.push('Accuracy');
    }
  }

  createDataArray() {
    let data = [];
    if (this.geodetic) {
      this.coordinates.forEach(coordinate => {
        let accuracy = 0.0;
        if (coordinate['accuracy'] !== null && coordinate['accuracy'] !== undefined && coordinate['accuracy'] !== '?') {
          accuracy = coordinate['accuracy'].toFixed(2);
        }
        const coord = {
          Latitude: coordinate['latitude'].toFixed(8) + '°',
          Longitude: coordinate['longitude'].toFixed(8) + '°',
          Elevation: coordinate['altitude'].toFixed(2) + 'm',
          Accuracy: accuracy !== 0.0 ? accuracy : '?'
        };
        data.push(coord);
      });
      // data = this.coordinates;
    } else {
      this.coordinates.forEach(coordinate => {
        let accuracy = 0.0;
        if (coordinate['accuracy'] !== null && coordinate['accuracy'] !== undefined && coordinate['accuracy'] !== '?') {
          accuracy = coordinate['accuracy'].toFixed(2);
        }
        const coord = {
          X: coordinate['X'].toFixed(2),
          Y: coordinate['Y'].toFixed(2),
          Z: coordinate['Z'].toFixed(2),
          Accuracy: accuracy !== 0.0 ? accuracy : '?'
        };
        data.push(coord);
      });
    }
    this.dataSource.data = data;
    if(this.coordinatestable)
    this.coordinatestable.renderRows();
  }

  closeDialog() {
    if (this.coordModified) {
      const confirmRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          message: `Changes are not saved, do you want to close the Dialog?`,
          title: 'Confirmation ?'
        }
      });
      confirmRef.afterClosed().subscribe(result  => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
    
  }
}
