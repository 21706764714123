import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { removeCSRSetting, persistCRSSetting, removeUTMZone, persistUTMZone, getUTMZone } from 'src/app/share/utils';
import { StoreService } from 'src/app/services/store.service';

interface CRSOption {
  value: string;
  text: string;
}

interface UTMZoneOption {
  value: number;
  label: string;
}

@Component({
  selector: 'app-setting-coordinates-reference-dialog',
  templateUrl: './setting-coordinates-reference-dialog.component.html',
  styleUrls: ['./setting-coordinates-reference-dialog.component.scss']
})
export class SettingCoordinatesReferenceDialogComponent implements OnInit {

  crsList = [
    {value: 'Geodetic', text: 'Geodetic'},
    {value: 'Lambert72', text: 'Lambert 72'},
    {value: 'Lambert2008', text: 'Lambert 2008'},
    {value: 'RDNAP', text: 'RD'},
    {value: 'UTM', text: 'UTM'},
    {value: 'BGS2005', text: 'BGS2005'}
  ];

  isUTMCrs: boolean = false;


  utmZoneOptions: UTMZoneOption[] = [];


  placeholdername: string;

  crsSettingForm: FormGroup;
  crsSetting: any;

  @ViewChild('fform', { static: false}) crsSettingFormDirective;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<SettingCoordinatesReferenceDialogComponent>
  ) {
    if (data !== undefined && data !== null) {
      const crs = data.crs;
      if (crs !== null && crs !== undefined) {
        this.crsSetting = this.crsList.find(c => c.value === crs);
      } else {
        this.crsSetting = this.crsList[0];
      }
    }
  }

  ngOnInit() {
    this.isUTMCrs = false;
    this.createForm();
    this.utmZoneOptions = this.generateUtmZoneOptions();

    // Subscribe to changes in the CRS selection
    if(this.crsSettingForm.get('crs')) {
      this.crsSettingForm.get('crs').valueChanges.subscribe((value) => {
        this.onCrsChange(value);
      });     
    }

  }

  createForm() {
    let crsValue = '';
    if (this.crsSetting !== null && this.crsSetting !== undefined) {
      crsValue = this.crsSetting.value;
    }

    this.crsSettingForm = this.fb.group({
      crs: [crsValue, Validators.required],
      utmzone: [],
      hemisphere: ['']
    });
    if(crsValue == 'UTM') {
      this.isUTMCrs = true;
      this.loadSavedCrsSettings();
    }
  }

  closeDialog(e) {
    e.preventDefault();
    const result = {
      event: 'Cancel'
    };
    this.dialogRef.close();
    e.stopPropagation();
  }

  onSubmit() {
    const data = this.crsSettingForm.value;
    this.crsSetting = data.crs;
    const utmzone = data.utmzone;
    const hemisphere = data.hemisphere; // 'N' or 'S'

    // Combine hemisphere and zone number into a single string as expected by the API
    const zone = `${hemisphere}${utmzone}`;

    removeCSRSetting();
    persistCRSSetting(this.crsSetting);

    removeUTMZone();
    if(utmzone) {
      persistUTMZone(zone);    
    }

    this.dialogRef.close();
  }

  onCrsChange(selectedCrs: string): void {
    this.isUTMCrs = selectedCrs === 'UTM';

    if (this.isUTMCrs) {
      // Make 'utmzone' and 'hemisphere' fields required
      if(this.crsSettingForm.get('utmzone')) {
        this.crsSettingForm.get('utmzone').setValidators(Validators.required);
      }
      if(this.crsSettingForm.get('hemisphere')) {
        this.crsSettingForm.get('hemisphere').setValidators(Validators.required);
      }
      
    } else {
      // Clear and remove validators from 'utmzone' and 'hemisphere'
      if(this.crsSettingForm.get('utmzone')){
        this.crsSettingForm.get('utmzone').clearValidators();
        this.crsSettingForm.get('utmzone').setValue('');
      }
      if(this.crsSettingForm.get('hemisphere')) {
        this.crsSettingForm.get('hemisphere').clearValidators();
        this.crsSettingForm.get('hemisphere').setValue('');
      }
    }
    if(this.crsSettingForm.get('utmzone')){
      this.crsSettingForm.get('utmzone').updateValueAndValidity();
    }
    if(this.crsSettingForm.get('hemisphere')) {
      this.crsSettingForm.get('hemisphere').updateValueAndValidity();
    }
  }

  loadSavedCrsSettings(): void {
    const savedZone = getUTMZone();
    if (savedZone) {
      const hemisphere = savedZone.charAt(0); // First character is the hemisphere
      const zoneNumber = parseInt(savedZone.slice(1), 10); // The rest is the zone number

      this.crsSettingForm.patchValue({
        utmzone: zoneNumber,
        hemisphere: hemisphere
      });

      this.isUTMCrs = true; // Show UTM-specific fields
    }
  }

  generateUtmZoneOptions(): UTMZoneOption[] {
    const options: UTMZoneOption[] = [];
    for (let i = 1; i <= 60; i++) {
      options.push({
        value: i,
        label: `Zone ${i} (${(i - 1) * 6 - 180}° to ${i * 6 - 180}°)`
      });
    }
    return options;
  }

  isValidZone(zone: string): boolean {
    if (zone.length < 2) {
      return false;
    }
    const NS = zone.charAt(0);
    if (NS !== 'N' && NS !== 'S') {
      return false;
    }
    const z = zone.substring(1);
    const zi = parseInt(z, 10);
    return zi >= 1 && zi <= 60;
  }

}

