import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FeatureService } from 'src/app/services/feature.service';
import { StoreService } from 'src/app/services/store.service';
import { Feature } from 'src/app/share/feature/feature';
import { Template } from 'src/app/share/template';
import {} from 'googlemaps';

import { getGeomFormToShow, getProject, getTemplate, getTemplates, getToken, persistTemplates } from 'src/app/share/utils';
import { ChooseLabelDialogComponent } from '../dialogs/choose-label-dialog/choose-label-dialog.component';
import { FeatureMapItemDialogComponent } from '../dialogs/feature-map-item-dialog/feature-map-item-dialog.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { Observable, of } from 'rxjs';
import { GeomFormsListComponent } from '../dialogs/geom-forms-list/geom-forms-list.component';

import { getAdminToken } from 'src/app/share/utils';
import { Style } from 'src/app/share/style';
import { StyleServiceService } from 'src/app/services/style-service.service';

import { removeGeomFormToShow } from 'src/app/share/utils';
import { persistGeomFormToShow } from 'src/app/share/utils';
import { MapItemDialogComponent } from '../dialogs/map-item-dialog/map-item-dialog.component';
import { TemplateService } from 'src/app/services/template.service';


export interface Coordinate {
  featureId: string;
  latitude: any;
  longitude: any;
}

const POINT_STYLES : string[] = [
  'circle',
  'circle_filled',
  'square',
  'square_filled',
  'triangle_filled',
  'triangle_right',
  'triangle_left',
  'plus_sign',
  'multiplication_sign',
  'greater_than',
  'greater_or_equal',
  'less_than',
  'less_or_equal'
];

const STYLES_SVG : any[] = [
  {
    "circle": "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
  },
  {
    "circle_filled": "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
  },
  {
    "square": "M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
  },
  {
    "square_filled": "M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"
  },
  {
    "triangle_filled": "M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z"
  },
  {
    "triangle_right": "M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
  },
  {
    "triangle_left": "M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
  },
  {
    "plus_sign": "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
  },
  {
    "multiplication_sign":"M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
  },
  {
    "greater_than": "M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
  },
  {
    "greater_or_equal":""
  },
  {
    "less_than":"M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
  },
  {
    "less_or_equal":""
  }
]

const LINE_COLORS: string[] = [
  "red",
  "rgb(23, 60, 128)",
  "blue",
  "rgb(10, 20, 96)",
  "rgb(3, 4, 96)",
  "yellow",
]


export const POINT = 'Point';
export const POLYLINE = 'LineString';
export const POLYGON = 'Polygon';

@Component({
  selector: 'app-feature-multiple-form-data-map',
  templateUrl: './feature-multiple-form-data-map.component.html',
  styleUrls: ['./feature-multiple-form-data-map.component.scss']
})
export class FeatureMultipleFormDataMapComponent implements OnInit, OnDestroy, AfterViewInit  {

  MarkerLabelOverlay = class extends google.maps.OverlayView {
  private fields: { field: string, value: string }[];
  private position: google.maps.LatLng;
  private div: HTMLDivElement | null = null;
  private x: number = 0;
  private y: number = 0;

  constructor(fields: { field: string, value: string }[],
              position: google.maps.LatLng, private _map: google.maps.Map,
              _x: number = 0, _y: number = 0) {
    super();
    this.fields = fields;
    this.position = position;
    this.x = _x;
    this.y = _y;
    this.setMap(_map);
  }

  onAdd() {
    this.div = document.createElement('div');
    this.div.className = 'marker-label';
    this.div.style.position = 'absolute';
    this.div.innerHTML = this.getLabelText();
    const panes = this.getPanes();
    panes.overlayLayer.appendChild(this.div);
  }

  draw() {
    const overlayProjection = this.getProjection();
    const position = overlayProjection.fromLatLngToDivPixel(this.position);
    if (this.div) {
      this.div.style.left = (position.x + this.x) + 'px';
      this.div.style.top = (position.y + this.y) + 'px';
    }
  }

  onRemove() {
    if (this.div && this.div.parentNode) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  private getLabelText(): string {
    return this.fields.map(field => `<div><strong>${field.field}:</strong> ${field.value}</div>`).join('');
  }
}

  template: Template;
  templateName: string;
  address: string;

  map2: google.maps.Map;
  latitude: number;
  longitude: number;
  title = 'MyCumulus';
  zoom: number;
  features: Feature[];
  coordinates: Coordinate[];
  polylines: any[];
  centralFeatures: Feature[] = [];
  dimensions = [];
  offsets = [];

  polygones: any[];
  polygoneCoordinates: any[];
  polygon: any;
  polyline: any;
  polygonType = false;
  polylineType  = false;
  pointType  = false;
  polygonReady = false;
  geomType: string;
  style: Style;

  polygonFeatures: any[] = [];
  pointFeatures: any[] = [];
  polylineFeatures: any[] = [];

  @ViewChild('map', { static: true })
  mapElement!: ElementRef<HTMLDivElement>;

  poly: google.maps.Polyline;
  geodesicPoly: google.maps.Polyline;

  g_markers: google.maps.Marker[] = [];
  g_polylines: google.maps.Polyline[] = [];
  g_polygones: google.maps.Polygon[] = [];

  map!: google.maps.Map;
  dynamicStyle: boolean;

  @ViewChild('search', {static: true}) public searchElementRef: ElementRef;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  offsetsCalculated: boolean = false;

  coordinates$: Observable<Coordinate[]> = of([]);
  polylines$: Observable<any[]> = of([]);
  polygones$: Observable<any[]> = of([]);

  pointsMidLat = null;
  pointsMidLon = null;
  polylinesMidLat = null;
  polylinesMidLon = null;
  polygonesMidLat = null;
  polygonesMidLon = null;

  //map: any;
  searchBox: any;
  showFieldValuesOnMap: boolean = false;
  show_value_on_map: boolean = false;
  show_point_numbers: boolean = false;

  constructor(
    private zone: NgZone,
    private styleService: StyleServiceService,
    private featureService: FeatureService,
    private templateService: TemplateService,
    private dialog: MatDialog,
    private store: StoreService,
  ) {}

  mapReady(event: any) {}

  ngOnInit() {
    if(this.store.archived) {
      return;
    }
    this.dynamicStyle =false;
    const token = getToken();
    const project = getProject();
    this.store.showLoading();
    this.templateService.getTemplates(token, project.id).subscribe(
      (result) => {
        persistTemplates(result);
        this.store.hideLoading();
        this.initializeMapElement();
      },
      (error) => {
        this.store.hideLoading();
        this.initializeMapElement();
      }
    )

  }

  initializeMapElement() {
    this.clearMaps();
    this.initMapElement();
    const templates = getTemplates();
    let formsToShow = getGeomFormToShow();
    if(!formsToShow || formsToShow.length <= 0) {
      this.saveDefaultFormsToShow();
      formsToShow = getGeomFormToShow();
    }

    this.show_value_on_map =  JSON.parse(localStorage.getItem('show_value_on_map'));
    this.show_point_numbers = JSON.parse(localStorage.getItem('show_point_numbers'));
    if(this.store.features && this.store.features.length > 0) {
      this.features = this.store.features;
      if(this.features && this.features.length > 0) {
        this.centralFeatures = [...this.features];
      }
      this.configureMapElement(this.features, LINE_COLORS[0]);
    } else {
      for(let index in templates){
        const t = templates[index];
        if(formsToShow) {
          const res = formsToShow[t.id];
          if(res) {
            const uri = 'templates/' + t.id + '/' + 'features';
            this.updateTemplateMap(t, uri, LINE_COLORS[index]);
          }
        }
      }

      // for(let index in this.store.templates){
      //   const t = this.store.templates[index];
      //   if(formsToShow) {
      //     const res = formsToShow[t.id];
      //     if(res) {
      //       const uri = 'templates/' + t.id + '/' + 'features';
      //       this.updateTemplateMap(t, uri, LINE_COLORS[index]);
      //     }
      //   }
      // }
    }

    this.store.template.subscribe(res => {
      if(!this.store.features || this.store.features.length <= 0) {
        if(this.store.template.value) {
          this.initMapElement();
          const n_uri = 'templates/' + res.id + '/features';
          this.updateTemplateMap(res, n_uri,  LINE_COLORS[0]);
        }
      }
    });

    this.store.changedMultipleformsonmap.subscribe(res => {
      if(this.store.changedMultipleformsonmap.value) {
        if(res && res > 0) {
          this.clearMaps();
          this.initMapElement();
          const formsToShow = getGeomFormToShow();
          for (let index in this.store.templates) {
            const t = this.store.templates[index];
            if(formsToShow) {
              const res = formsToShow[t.id];
              if(res) {
                const uri = 'templates/' + t.id + '/' + 'features';
                this.updateTemplateMap(t, uri, LINE_COLORS[index]);
              }
            }
          }
        }
      }
    });
    this.openMap();
  }

  saveDefaultFormsToShow() {
    const labels = [];

    for (const key of this.store.templates) {
      const templateId = key['id'];
      labels.push(templateId);
    }
    const obj = {};
    if(labels && labels.length > 0) {
      for(const templName of labels) {
        obj[templName] = true;
      }
      removeGeomFormToShow();
      if(obj) {

        persistGeomFormToShow(obj);
      }
      console.log(obj);
    }
  }

  initMapElement() {
    this.latitude = 51.4;
    this.longitude = 4.900007;
    this.zoom = 15;
    this.initMap();
    this.features = [];
  }

  openMultipleFormMapData() {
    if(this.store.proClass === "template" || this.store.proClass === "domain") {
      return;
    }
    this.store.hideTemplate();
    this.store.showMultipleFormsOnMap();
  }

  showListOfGeometryForms() {
    console.log(this.store.templates);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.height = 'auto';
    dialogConfig.hasBackdrop = true;
    this.zone.run(() => {
      const dialogRef = this.dialog.open(GeomFormsListComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result  => {
        if(result && Object.keys(result).length > 0) {
          this.openMultipleFormMapData();
        } else {
         // this.initMap();
         // this.clearMaps();
        }
      });
    })

  }

  ngAfterViewInit() {}

  clearMaps() {
    this.coordinates$ = of([]);
    this.polygones$ = of([]);
    this.polylines$ = of([])
    this.pointType = false;
    this.polylineType = false;
    this.polygonType = false;
    this.polygonReady = false;
    this.features = [];
  }

  showFormData() {
    const selectedTemplateToShow = getGeomFormToShow();
    if(selectedTemplateToShow && selectedTemplateToShow.length > 0) {
      if(this.store.templates && this.store.templates.length > 0) {
        this.store.showLoading();
        this.store.templates.forEach((t, index) => {
          const val = selectedTemplateToShow[t.id];
          if(val) {
            const uri = 'templates/' + t.id + '/' + 'features';
            this.updateTemplateMap(t, uri, LINE_COLORS[index]);
          }
        });
        this.store.hideLoading();
      }
    }
  }

  updateTemplateMap(template, uri, lineColor) {
    const geomType = template.feature.geometry_type;
    this.geomType = template.feature.geometry_type;
    this.polygonReady = false;
    //return;
    this.templateName = template.name;
    const token = getToken();
    const project = getProject();

    this.features = [];

    // this.offsetsCalculated = false;
    if(template && template.default_style_id) {
      this.getStyle(template.default_style_id, uri, lineColor);
    } else {
      this.style = null;
      this.getFeatures(uri, this.features, false, lineColor);
    }
  }

  initMap(): void {
    const mapProp = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: this.zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProp);
  }

  addMarker(position: google.maps.LatLng, fields: { field: string, value: string }[],
    x: number = 0, y: number = 0): void {
    const marker = new google.maps.Marker({
      position,
      map: this.map
    });

    // Create and add custom label overlay
    const overlay = new this.MarkerLabelOverlay(fields, position, this.map, x, y);
    overlay.setMap(this.map);
  }

  setShowFieldValuesOnMap(isChecked: boolean) {
    this.show_value_on_map = isChecked;
    localStorage.setItem('show_value_on_map', JSON.stringify(this.show_value_on_map));
    this.initializeMapElement();
  }

  setShowPointNumbers(isChecked: boolean) {
    this.show_point_numbers = isChecked;
    localStorage.setItem('show_point_numbers', JSON.stringify(this.show_point_numbers));
    this.initializeMapElement();
  }

  openMap(): void {
    this.pointType = true;
    this.polylineType = true;
    this.polygonType = true;
    this.polygonReady = true;
  }

  configureMapElement(features, color = ""): void {
    if (features.length > 0) {
      const resArray = [];
      this.store.showLoading();
      for(let feature of features) {
        //let feature = features[i];
        if (feature && feature.geometry) {
          if (feature.geometry.type === POINT) {
            if(feature.geometry.coordinates[0] === 0.0 && feature.geometry.coordinates[1] === 0.0) {
              continue;
            }
            this.pointFeatures.push(feature);
            let changeStyle = false;
            const svgMarker = {
              path: null,
              strokeWeight: 1,
              rotation: 0,
              scale: 1,
              anchor: new google.maps.Point(0, 0)
            };
            svgMarker['path'] = "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z";

            if(this.style) {
              let rgbColor = 'red';
              let nameValues = {};
              this.buildObjectData(feature.attributes, nameValues, '');

              let attribute = this.getStyleAttributes(nameValues, this.offsetsCalculated);

              if (attribute && attribute.color && attribute.color.length >= 3) {
                rgbColor = `rgb(${attribute.color[0]}, ${attribute.color[1]}, ${attribute.color[2]})`;

                changeStyle = true;
              }

              let pointStyle = this.getPointStyle(attribute);
              if (pointStyle) {
                if(attribute.point_style === "circle") {
                  svgMarker['path'] = google.maps.SymbolPath.CIRCLE;
                  svgMarker['scale'] = 4;
                  svgMarker['strokeWeight'] = 2;
                  svgMarker['fillOpacity'] = 0;
                  svgMarker['strokeOpacity'] = 1;
                  svgMarker['strokeColor'] = rgbColor;
                }  else if(attribute.point_style === "circle_filled") {
                  svgMarker['path'] = google.maps.SymbolPath.CIRCLE;
                  svgMarker['scale'] = 4;
                  svgMarker['strokeWeight'] = 2;
                  svgMarker['fillOpacity'] = 1;
                  svgMarker['fillColor'] = rgbColor;
                  svgMarker['strokeColor'] = rgbColor;
                } else  {
                  svgMarker['path'] = pointStyle;
                  if(attribute.point_style !== "plus_sign") {
                    svgMarker['scale'] = 0.5;
                    svgMarker['strokeWeight'] = 0.5;
                    if(attribute.point_style.indexOf('filled') >= 0) {
                      svgMarker['strokeWeight'] = 2;
                      svgMarker['fillOpacity'] = 1;
                      svgMarker['fillColor'] = rgbColor;
                      svgMarker['strokeColor'] = rgbColor;
                    }
                    if(attribute.point_style === 'multiplication_sign') {
                      svgMarker['rotation'] = 45;
                    }
                  } else {
                    svgMarker['scale'] = 1.2;
                    svgMarker['strokeWeight'] = 1;
                    svgMarker['fillOpacity'] = 1;
                    svgMarker['fillColor'] = rgbColor;
                    svgMarker['rotation'] = 90;
                  }
                  if(rgbColor) {
                    svgMarker['fillColor'] = rgbColor;
                    svgMarker['strokeColor'] = rgbColor;
                  }
                  svgMarker['anchor'] = new google.maps.Point(7.7, 8.0);
                }

                changeStyle = true;
              } else {
                let pointStyle = this.getPointStyle(null);
                svgMarker['path'] = pointStyle;

                svgMarker['scale'] = 1.2;
                svgMarker['strokeWeight'] = 1;
                svgMarker['fillOpacity'] = 1;
                svgMarker['fillColor'] = "red";
                svgMarker['strokeColor'] = "red";
                svgMarker['rotation'] = 90;
                svgMarker['anchor'] = new google.maps.Point(7.7, 8.0);
              }
            } else {
              let pointStyle = this.getPointStyle(null);
              svgMarker['path'] = pointStyle;

              svgMarker['scale'] = 1.2;
              svgMarker['strokeWeight'] = 1;
              svgMarker['fillOpacity'] = 1;
              svgMarker['fillColor'] = "red";
              svgMarker['rotation'] = 90;
              svgMarker['anchor'] = new google.maps.Point(7.7, 8.0);
            }

            const marker = new google.maps.Marker({
              map: this.map,
              draggable: false,
              clickable: true,
              position: { lat: feature.geometry.coordinates[0], lng: feature.geometry.coordinates[1]},
            })

            if(this.show_value_on_map) {
              const label = this.get_label_data(feature);
              if(label && label.length > 0) {
                // marker.setLabel({
                //   text: label,
                //   color: 'blue',
                //   fontSize: '12px'
                // });

                const overlay = new this.MarkerLabelOverlay(label,
                  new google.maps.LatLng(feature.geometry.coordinates[0], feature.geometry.coordinates[1],),
                 this.map, 8, 4);
                overlay.setMap(this.map);
              }
            }
            marker.setCursor('pointer');
            marker.setIcon(svgMarker);

            marker.addListener('click', (event) => {
              this.openDataFeatureItem(feature);
            });
            this.g_markers.push(marker);
          } else if (feature.geometry.type === POLYLINE) {
            this.polylineFeatures.push(feature);
            this.createPolyline(feature);
          } else if (feature.geometry.type === POLYGON) {
            this.polygonFeatures.push(feature);
            this.createPolygon(feature);
            // this.polygonReady = true;
          }
        }
      }

      var zoom = 19;
      if (features && features[0] && features[0].geometry && features[0].geometry.type === POINT) {
        this.coordinates$ = of(resArray);
        this.getCenterElt(resArray, POINT);
        // zoom = 19;
      } else if (features && features[0] && features[0].geometry &&  features[0].geometry.type === POLYLINE) {
        const middle = Math.floor(this.g_polylines.length / 2);
        const middlePath = this.g_polylines[middle].getPath();
        const firstPathLength = middlePath.getLength();

        if(middlePath.getAt(firstPathLength - 1).lat()) {
          this.latitude = middlePath.getAt(firstPathLength - 1).lat();
        }
        if(middlePath.getAt(firstPathLength - 1).lng()) {
          this.longitude = middlePath.getAt(firstPathLength - 1).lng();
        }

      } else if (features && features[0] && features[0].geometry &&  features[0].geometry.type === POLYGON) {
        const middle = Math.floor(this.g_polygones.length / 2);
        const middlePath = this.g_polygones[middle].getPath();
        const firstPathLength = middlePath.getLength();

        if(middlePath.getAt(firstPathLength - 1).lat()) {
          this.latitude = middlePath.getAt(firstPathLength - 1).lat();
        }
        if(middlePath.getAt(firstPathLength - 1).lng()) {
          this.longitude = middlePath.getAt(firstPathLength - 1).lng();
        }
      }
      var myLatLng=new google.maps.LatLng(this.latitude, this.longitude);

      this.map.setZoom(zoom);
      this.map.setCenter(myLatLng);
      this.store.hideLoading();
    }
    //this.zoom = 15;
  }

  get_label_data(feature: any): any {
    const templates = getTemplates();
    const template = templates.find(temp => temp.id === feature.template_id);
    let label = [];
    const attributes = feature.attributes;
    if (template) {
      const form = template.form;
      if (form) {
        const fields = form.fields;
        for(const field of fields) {
          const fieldname = field['name'];
          if(field['_class'] == 'fieldset' || field['_class'] === 'fieldsetarray'){
            const absAttribute = attributes[fieldname];
            this.get_label_data_attset(field, absAttribute, label);
          } else {
              if(field['show_value_on_map']) {
                if(attributes[fieldname]) {
                  label.push({ field: field['label'], value: attributes[fieldname] });
                  //label += label === '' ? attributes[fieldname] : `, ${attributes[fieldname]}`;
                }
              }
          }
        }
      }
    }
    return label;
  }

  get_label_data_attset(fieldset, attributeset: any, label: any): any {
    const fields = fieldset.fields;
    for(const field of fields) {
      const fieldname = field['name'];
      if(field['_class'] == 'fieldset' || field['_class'] === 'fieldsetarray'){
        const absAttribute = attributeset[fieldname];
        this.get_label_data_attset(field, absAttribute, label);
      } else {
        if(field['show_value_on_map']) {
          if (attributeset[fieldname]) {
            label.push({ field: field['label'], value: attributeset[fieldname] });
          }
        }
      }
    }
  }

  getCenterElt(arr, type) {
    if (type === POINT) {
      let middle = 0;
      if(this.g_markers && this.g_markers.length > 0) {
        middle = Math.floor(this.g_markers.length/2);
        const marker = this.g_markers[middle];
        this.latitude = marker.getPosition().lat();
        this.longitude = marker.getPosition().lng();
      }
    } else if (type === POLYLINE) {
      if(arr.length > 0) {
        this.polylines$ = of(arr);
        const middle = Math.floor(arr.length / 2);
        const middlePath = arr[middle].paths;
        const firstPathLength = middlePath.length;
        if(middlePath[firstPathLength - 1].latitude) {
          this.polylinesMidLat = middlePath[firstPathLength - 1].latitude;
        }
        if(middlePath[firstPathLength - 1].longitude) {
          this.polylinesMidLon = middlePath[firstPathLength - 1].longitude;
        }
      }

    } else if (type === POLYGON) {
      if(arr.length > 0) {
        this.polygonType = true;
        this.polygones$ = of(arr);
        let middle = 0;
        if(arr && arr.length > 0) {
          if (arr.length > 2) {
            middle = Math.floor(arr.length / 2);
          }
          const middlePath = arr[middle].paths;
          const firstPathLength = middlePath.length;
          if (middlePath && middlePath.length > 0) {
            if(middlePath[firstPathLength - 1].lat) {
              this.polygonesMidLat = middlePath[firstPathLength - 1].lat;
            }
            if(middlePath[firstPathLength - 1].lng) {
              this.polygonesMidLon = middlePath[firstPathLength - 1].lng;
            }
          }
        }
      }
      this.polygonReady = true;
    }

  }

  getFeatures(url: string, features: any[], urlWithVersion = false, lineColor) {
    const token = getToken();
    const project = getProject();

    this.store.showLoading();
    this.featureService.getFeatures(token, url, project, urlWithVersion).subscribe((data) => {
      if (features === undefined) {
        features = data['instances'];
      } else {
        features = features.concat(data['instances']);
      }

      if ((data['metadata']) && (data['metadata']['uris']['next']) ) {
        let next = data['metadata']['uris']['next'];
        if(next.startsWith('/v1/')) {
          const tempArr = next.split('/v1/');
          if(tempArr && tempArr.length > 1) {
            next = tempArr[1];
          }
        }
        this.getFeatures(next, features, false, lineColor);
      } else {
        // this.coordinates = [];
        // this.polygones = [];
        // this.polylines = [];
        // this.features = features;
        if(features && features.length > 0) {
          this.centralFeatures = [...features];
        }
        this.configureMapElement(features, lineColor);
      }
      this.store.hideLoading();
    },
    error => {
      this.showErroMessage(error);

    });
  }

  getPolygonCoordinates(draggablePolygon) {
    const len = draggablePolygon.getPath().getLength();
    const polyArrayLatLng = [];

    for (let i = 0; i < len; i++) {
      const vertex = draggablePolygon.getPath().getAt(i);
      const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
      polyArrayLatLng.push(vertexLatLng);
    }
  }

  selectFieldToShow() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ChooseLabelDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      res => {

      }
    );
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  setCoordinates(feature) {
    const coords = feature.geometry.coordinates;
    this.latitude = coords[0];
    this.longitude = coords[1];
  }

  openDataItem(featureId) {
    const feature = this.centralFeatures.find(f => f.id === featureId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      feature
    };

    const dialogRef = this.dialog.open(FeatureMapItemDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDataFeatureItem(feature: Feature) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '500px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      feature
    };

    //const dialogRef = this.dialog.open(FeatureMapItemDialogComponent, dialogConfig);
    const dialogRef = this.dialog.open(MapItemDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      //return;
    });
  }

  openPolygonAndPolylineDataFeatureItem(feature: Feature) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '500px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      feature
    };

    //const dialogRef = this.dialog.open(FeatureMapItemDialogComponent, dialogConfig);
    this.zone.run(() => {
      this.dialog.open(FeatureMapItemDialogComponent, dialogConfig);
    });
  }

  getPointStyle(attribute): string{
    if (attribute && attribute.point_style) {
      const pStyle = attribute.point_style;
      for(let i = 0; i < POINT_STYLES.length; i++) {
        if(pStyle === POINT_STYLES[i]){
          if(pStyle === "circle_filled") {
            return "google.maps.SymbolPath.CIRCLE";
            //scale: 4,
            //strokeWeight: 2,
          }
          const obj = STYLES_SVG[i];
          if(obj && obj[pStyle]) {
            return obj[pStyle];
          }
        }
      }
    } else {
      const obj = STYLES_SVG[7];
      if(obj && obj['plus_sign']) {
        return obj['plus_sign'];
      }
    }

    return null;
  }

  createPolygon(feature: Feature) {
    // Define the LatLng coordinates for the polygon.
    const coordinates = feature.geometry.coordinates;
    const polygonCoords = feature.geometry.coordinates;
    let LineWidth = 2;
    let ColorByAttributes = false;
    let paths = [];
    //const triangleCoords: google.maps.LatLngLiteral[] = [];
    polygonCoords.forEach(element => {
      const coords =  element as any[];
      paths = [];
      if(coords instanceof Array) {
        coords.forEach(coord => {
          const item = { lat : coord[0], lng : coord[1] };
          paths.push(item);
        });
      }
    });
    const coords: google.maps.LatLngLiteral[] = paths;

    const polygoneOptions ={
      map:this.map,
      paths: coords,
      strokeOpacity: 0.8,
      strokeWeight: 0.4,
      fillOpacity: 0.35,
      clickable: true
    }

    if (this.style) {
      try {
        let feat = new Feature(feature);
        let nameValues = {};
        this.buildObjectData(feat.attributes, nameValues, '');
        let attribute = this.getStyleAttributes(nameValues, this.offsetsCalculated);

        if (attribute && attribute.color && attribute.color.length >= 3) {
          const rgbColor = `rgb(${attribute.color[0]}, ${attribute.color[1]}, ${attribute.color[2]})`;
          polygoneOptions["fillColor"] = rgbColor;
        }

        if (attribute && attribute.polygon_pattern) {
          if (attribute.polygon_pattern === 'dash'
                || attribute.polygon_pattern === 'dash_dot_dot'
                || attribute.polygon_pattern === 'dash_dot') {
            const lineSymbol = {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4,
            };
            const icons = [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "20px",
              },
            ];
            polygoneOptions["icons"] = icons;
            //polygoneOptions.strokeDasharray = [10, 5];
          } else if (attribute.polygon_pattern === 'dot') {
            const lineSymbol = {
              path: "M 0,1 0,1",
              strokeOpacity: 1,
              scale: 4,
            };
            const icons = [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "20px",
              },
            ];
            polygoneOptions["icons"] = icons;
            //polygoneOptions.strokeDasharray = [1, 10];
          } else if (attribute.polygon_pattern === 'dash_dot') {
            //polygoneOptions.strokeDasharray = [10, 5, 1, 5];
            LineWidth = LineWidth - 1;
          } else if (attribute.polygon_pattern === 'dash_dot_dot') {
            // polygoneOptions.strokeDasharray = [10, 5, 1, 5, 1, 5];
            LineWidth = LineWidth - 1;
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      polygoneOptions["fillColor"] = 'red';
      polygoneOptions["strokeColor"] = 'red';
    }

    // Construct the polygon.
    const polygone = new google.maps.Polygon(polygoneOptions);
    const position = this.getPolygonBoundsCenter(polygone);


    if(this.show_value_on_map) {
      const label = this.get_label_data(feature);
      if(label && label.length) {
        const overlay = new this.MarkerLabelOverlay(label, position, this.map);
        overlay.setMap(this.map);
      }
    }

    this.g_polygones.push(polygone);
    //polygone.setMap(this.map);

    polygone.addListener('click', (event) => {
      this.openPolygonAndPolylineDataFeatureItem(feature);
    });

    if (this.show_point_numbers) {
      const polyg_size = coords.length;
      coords.forEach((vertex, index) => {
          if(index === polyg_size-1)
            return;
          const marker = new google.maps.Marker({
            position: vertex,
            map: this.map,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 0, // Invisible icon
            },
            label: {
              text: (index + 1).toString(),
              color: 'black',
              fontSize: '11px',
              fontWeight: 'small'
            }
          });
      });
    }
  }

  getPolygonBoundsCenter(polygon: google.maps.Polygon): google.maps.LatLng {
    const bounds = new google.maps.LatLngBounds();
    polygon.getPath().forEach((path) => {
      bounds.extend(path);
    });
    return bounds.getCenter();
  }

  createPolyline(feature: Feature) {
    const polylineOptions = {
      path: [],
      strokeColor: 'red',
      strokeWeight: 1,
      zIndex: 1.0,
      clickable: true,
      anchor: new google.maps.Point(0, 0),
      tag: feature.id
    };

    //polylineOptions.strokeColor = lineColor;

    let LineWidth = 2;
    let ColorByAttributes = false;

    if (this.style) {
      try {
        let feat = new Feature(feature);
        let nameValues = {};
        this.buildObjectData(feat.attributes, nameValues, '');
        this.offsetsCalculated = false;
        let attribute = this.getStyleAttributes(nameValues, this.offsetsCalculated);

        if (attribute && attribute.color && attribute.color.length >= 3) {
          const rgbColor = `rgb(${attribute.color[0]}, ${attribute.color[1]}, ${attribute.color[2]})`;
          polylineOptions["strokeColor"] = rgbColor;
          ColorByAttributes = true;

          if (ColorByAttributes) {
            polylineOptions["strokeColor"] = rgbColor;
            polylineOptions["fillColor"] = 'rgb(255, 255, 255)';
          }
        }

        if (attribute && attribute.line_style) {
          if (attribute.line_style === 'dash') {
            const lineSymbol = {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
            };

            const icons = [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "15px",
              },
            ];
            polylineOptions["icons"] = icons;
            polylineOptions["strokeOpacity"] = 0;

          } else if (attribute.line_style === 'dot') {
            const lineSymbol = {
              path: "M 0,1 0,1",
              strokeOpacity: 1,
              scale: 2,
            };
            const icons = [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "5px",
              },
            ];
            polylineOptions["icons"] = icons;
            polylineOptions["strokeOpacity"] = 0;
          } else if (attribute.line_style === 'dash_dot') {
            const lineSymbol = {
              path: "M 0,-1 0,1",
              strokeOpacity: 0.7,
              strokeWeight: 2,
              scale: 2,
              strokeDasharray: '10, 5, 2, 5'
            };
            const icons = [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "20px",
              },
            ];
            polylineOptions["icons"] = icons;
            polylineOptions["strokeOpacity"] = 0;
          } else if (attribute.line_style === 'dash_dot_dot') {
            const lineSymbol = {
              path: "M 0, -1 0,1",
              strokeOpacity: 1,
              scale: 2,
            };
            const icons = [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "15px",
              },
            ];
            polylineOptions["icons"] = icons;
            polylineOptions["strokeOpacity"] = 0;
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      polylineOptions["strokeColor"] = 'red';
    }
    polylineOptions.strokeWeight = Math.max(1, LineWidth);

    const linestringCoords = feature.geometry.coordinates;
    linestringCoords.forEach(item => {
      const coord = {
        latitude: item[0],
        longitude: item[1]
      };
      polylineOptions.path.push(new google.maps.LatLng(item[0], item[1]));
    });

    var polyline = new google.maps.Polyline(polylineOptions);

    const position = this.getPolylineMidpoint(polyline);

    if(this.show_value_on_map) {
      const label = this.get_label_data(feature);
      if(label && label.length) {
        const overlay = new this.MarkerLabelOverlay(label, position, this.map);
        overlay.setMap(this.map);
      }
    }

    this.g_polylines.push(polyline);
    polyline.setMap(this.map);

    polyline.addListener('click', () => {

      this.openPolygonAndPolylineDataFeatureItem(feature);
    });


    if (this.show_point_numbers) {
      polylineOptions.path.forEach((vertex, index) => {
          const marker = new google.maps.Marker({
            position: vertex,
            map: this.map,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 0, // Invisible icon
            },
            label: {
              text: (index+1).toString(),
              color: 'black',
              fontSize: '11px',
              fontWeight: 'small'
            }
          });
      });
    }
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  setMapType(mapTypeId: string) {
    // this.map.setMapTypeId(mapTypeId);
  }

  getPolylineMidpoint(polyline: google.maps.Polyline): google.maps.LatLng {
    const path = polyline.getPath();
    const length = path.getLength();
    let distance = 0;
    let midPointDistance = 0;

    // Calcul de la longueur totale de la polyligne
    for (let i = 1; i < length; i++) {
      distance += google.maps.geometry.spherical.computeDistanceBetween(path.getAt(i - 1), path.getAt(i));
    }

    midPointDistance = distance / 2;

    // Réinitialisation pour calculer le point médian
    distance = 0;

    // Trouver le point médian
    for (let i = 1; i < length; i++) {
      let segmentDistance = google.maps.geometry.spherical.computeDistanceBetween(path.getAt(i - 1), path.getAt(i));
      distance += segmentDistance;
      if (distance >= midPointDistance) {
        // Calculer le point exact du milieu du segment
        let ratio = (midPointDistance - (distance - segmentDistance)) / segmentDistance;
        let lat = path.getAt(i - 1).lat() + ratio * (path.getAt(i).lat() - path.getAt(i - 1).lat());
        let lng = path.getAt(i - 1).lng() + ratio * (path.getAt(i).lng() - path.getAt(i - 1).lng());
        return new google.maps.LatLng(lat, lng);
      }
    }

    return path.getAt(0);
  }


  getStyleAttributes(nameValue, offsetsCalculated){
      this.calculateOffsets(offsetsCalculated);
      let attribute = null;
      var index = 0;
      let dynamicStyle = false;

      for(let i = 0; i < this.style.indexes.length; i++){
        this.dynamicStyle = false;
        index = index + this.getIndex(this.style.indexes[i], nameValue) * Math.max(this.offsets[i], 1);
        if(this.dynamicStyle) {
          break;
        }
      }
      if(this.dynamicStyle) {
        let c = nameValue['color'];
        if(c && c.length > 0) {
          c = c.split(',');
        }
        attribute = {
          "color": c ,
          "line_style": nameValue['line_style'],
          "polygon_pattern": nameValue['polygon_pattern'],
          "point_style": nameValue['point_style']
        };
      } else if(index < this.style.attributes.length){
          attribute = this.style.attributes[index];
      }

      return attribute;
  }

  buildObjectData(attributes, nameValues, path) {
    let propertyNames = [];
    propertyNames = Object.getOwnPropertyNames(attributes);

    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      let name = propertyNames[i];
      const value = name.split('.');
      if (value.length > 1) {
        name = value[0];
        value.shift();
      }
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (attribute === null || attribute === undefined) {
        nameValues[currentPath] = null;
      } else {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
          } else {
            this.buildObjectData(attribute, nameValues, currentPath);
          }
        } else if (attribute instanceof Array) {

        } else {
          nameValues[name] = attributes[name];
        }
      }
    }
  }

  getIndex(styleIndex, nameValue){
      let v = nameValue[styleIndex.name];
      if(v) {
          for(let i = 0; i < styleIndex.values.length; i++){
              if(styleIndex.values[i] === v){
                  return i;
              }
              if((typeof(v) === 'string') && (styleIndex.values[i] == v)){
                return i;
              }
              if( (styleIndex.name === 'color') && (typeof(v) === 'string') && (styleIndex.values[i] === '$color')){
                this.dynamicStyle = true;
                return i;
              }
          }
          return styleIndex.values.length - 1;
      }
      return styleIndex.values.length - 1;
  }

  calculateOffsets(offsetsCalculated) {

    //if (!offsetsCalculated) {
      for(var i = 0; i < this.style.indexes.length; i++){
          this.dimensions[i] = this.style.indexes[i].values.length;
      }
      for(var i = this.style.indexes.length - 1; i >= 0; i--){
          if(i === this.style.indexes.length - 1){
            this.offsets[i] = 0;
          } else  if (i === this.style.indexes.length - 2){
            this.offsets[i] = this.dimensions[i+1];
          } else {
            this.offsets[i] = this.offsets[i+1] * this.dimensions[i+1];
          }
      }
      offsetsCalculated = true;
    //}
  }

  getStyle(styleId, uri, lineColor) {

    let isMyCumulusAdministrator = false;
    let token = getToken();

    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator = true;
      }
    }
    this.store.showLoading();
    this.style = null;
    this.styleService.getStyle(styleId, token, isMyCumulusAdministrator).subscribe(
      res => {
        if(res !== null) {
          this.style = res;
        }
        this.store.hideLoading();
        this.getFeatures(uri, this.features, false, lineColor);
      },
      errmess => {
        this.style = null;
        this.store.hideLoading();
    });
  }

  ngOnDestroy(): void {
  }


}
